import fetchCall from './ComunicationService';

const ServicesService = () => {
  const getServices = () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http('services/all', {}, headers, 'GET', {})
      .then((response) => response.services)
      .catch((error) => console.log(error));
  };

  const addServices = (data) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    return fetchCall
      .http('services/add', {}, headers, 'POST', data, true)
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  const getService = (id) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`services/service/${id}`, {}, headers, 'GET', {})
      .then((response) => response[0])
      .catch((error) => console.log(error));
  };

  const editServices = (id, data) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    return fetchCall
      .http(`services/service/${id}`, {}, headers, 'PUT', data, true)
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  const getServiceUploadUrl = (filename) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`services/service-upload-url`, {}, headers, 'POST', filename)
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  const deleteFileFromGCloud = (filename) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`services/delete-file`, {}, headers, 'POST', filename)
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  const deleteService = (id) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`services/service/${id}`, {}, headers, 'DELETE', {})
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  const remainder = (id) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`services/reminder/${id}`, {}, headers, 'GET', {})
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  return {
    getServices,
    addServices,
    getService,
    editServices,
    getServiceUploadUrl,
    deleteFileFromGCloud,
    deleteService,
    remainder,
  };
};

export default ServicesService();
