import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import WithValidToken from '../../common/hoc/WithValidToken';
import ServicesService from '../../Services/ServicesService';
import Spinner from '../../common/components/Spinner';
import './services.css';

const Services = () => {
  const history = useHistory();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    ServicesService.getServices().then((response) => {
      setServices(response);
      setLoading(false);
    });
  }, []);

  const handleDelete = (e) => {
    const id = e.target.dataset.id;
    if (
      window.confirm(`Esta seguro que desea borrar: ${e.target.dataset.name}`)
    ) {
      ServicesService.deleteService(id).then(() => {
        const updatedServices = services.filter(
          (service) => service._id !== id
        );
        setServices(updatedServices);
      });
    }
  };

  const handleRemainder = (event) => {
    const id = event.target.dataset.id;

    if (
      window.confirm(
        `Esta seguro que desea enviar la notificación a los usuarios de: ${event.target.dataset.name}`
      )
    ) {
      ServicesService.remainder(id).then(() => {
        window.alert('Listo!');
      });
    }
  };

  const renderServices = () => {
    if (services.length === 0) {
      return;
    }

    return services.map((service) => (
      <div key={service._id} className="item">
        <Link to={`/services/edit/${service._id}`} className="link">
          {service.name}
        </Link>
        <Link
          className="circle-button review-service"
          title="agregar nuevo servicio"
          to={`/review/${service._id}`}
          target="_blank"
        >
          +
        </Link>
        {service.service_type === 'streaming' && (
          <button
            className="circle-button reminder-service"
            onClick={handleRemainder}
            data-id={service._id}
            data-name={service.name}
          >
            <span role="img" aria-label="enviar recordatorio" className="emoji">
              📧
            </span>
          </button>
        )}
        <button
          className="circle-button delete-service"
          onClick={handleDelete}
          data-id={service._id}
          data-name={service.name}
        >
          &times;
        </button>
      </div>
    ));
  };

  const handleNewServices = (event) => {
    history.push('/services/new');
  };

  return (
    <div className="services-container">
      <div className="title">
        <h1>Servicios</h1>
        <button
          className="circle-button add-services"
          title="agregar nuevo servicio"
          onClick={handleNewServices}
        >
          +
        </button>
      </div>
      {loading && <Spinner />}
      {!loading && renderServices()}
    </div>
  );
};

export default WithValidToken(Services);
