import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import WithValidToken from '../../common/hoc/WithValidToken';
import ScheduleService from '../../Services/ScheduleService';
import Spinner from '../../common/components/Spinner';
import './schedules.css';

const Schedules = () => {
  const history = useHistory();
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    ScheduleService.getSchedules().then((response) => {
      setSchedules(response);
      setLoading(false);
    });
  }, []);

  const handleDelete = (e) => {
    const id = e.target.dataset.id;
    if (
      window.confirm(`Esta seguro que desea borrar: ${e.target.dataset.date}`)
    ) {
      ScheduleService.deleteSchedule(id).then(() => {
        const updatedServices = schedules.filter(
          (schedule) => schedule._id !== id
        );
        setSchedules(updatedServices);
      });
    }
  };

  const renderSchedules = () => {
    if (schedules.length === 0) {
      return;
    }

    return schedules.map((schedule) => (
      <div key={schedule._id} className="item">
        <Link to="#" className="link">
          {schedule.service.name}
        </Link>
        <Link to="#" className="link">
          {new Intl.DateTimeFormat('es-ES', {
            dateStyle: 'full',
            timeStyle: 'short',
          }).format(new Date(schedule.bookedDateTime))}
        </Link>

        <button
          className="circle-button delete-service"
          onClick={handleDelete}
          data-id={schedule._id}
          data-date={new Intl.DateTimeFormat('es-ES', {
            dateStyle: 'full',
            timeStyle: 'short',
          }).format(new Date(schedule.bookedDateTime))}
        >
          &times;
        </button>
      </div>
    ));
  };

  const handleNewSchedule = (event) => {
    history.push('/schedules/new');
  };

  return (
    <div className="services-container">
      <div className="title">
        <h1>Disponilidad</h1>
        <button
          className="circle-button add-services"
          title="agregar nuevo servicio"
          onClick={handleNewSchedule}
        >
          +
        </button>
      </div>
      {loading && <Spinner />}
      {!loading && renderSchedules()}
    </div>
  );
};

export default WithValidToken(Schedules);
