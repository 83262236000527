export default {
  6: {
    buenosDias: '7:00',
    primeraSiesta: '8:30 - 10:00 - Siesta 1',
    segundaSiesta: '12:00 - 13:30 - Siesta 2',
    terceraSiesta: '16:00 - 15:00 - Siesta 3',
    dormido: '20:00 - Dormido',
  },
  7: {
    buenosDias: '7:00',
    primeraSiesta: '8:30 - 10:00 - Siesta 1',
    segundaSiesta: '12:00 - 13:30 - Siesta 2',
    terceraSiesta: '16:00 - 15:00 - Siesta 3',
    dormido: '20:00 - Dormido',
  },
  8: {
    buenosDias: '7:00',
    primeraSiesta: '8:30 - 10:00 - Siesta 1',
    segundaSiesta: '12:00 - 13:30 - Siesta 2',
    terceraSiesta: '16:00 - 15:00 - Siesta 3',
    dormido: '20:00 - Dormido',
  },
  9: {
    buenosDias: '7:00',
    primeraSiesta: '9:30 - 11:00 - Siesta 1',
    segundaSiesta: '14:00 - 16:00 - Siesta 2',
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  10: {
    buenosDias: '7:00',
    primeraSiesta: '9:30 - 11:00 - Siesta 1',
    segundaSiesta: '14:00 - 16:00 - Siesta 2',
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  11: {
    buenosDias: '7:00',
    primeraSiesta: '9:30 - 11:00 - Siesta 1',
    segundaSiesta: '14:00 - 16:00 - Siesta 2',
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  12: {
    buenosDias: '7:00',
    primeraSiesta: '9:30 - 11:00 - Siesta 1',
    segundaSiesta: '14:00 - 16:00 - Siesta 2',
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  13: {
    buenosDias: '7:00',
    primeraSiesta: '10:00 - 11:00 - Siesta 1',
    segundaSiesta: '14:00 - 16:00 - Siesta 2',
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  14: {
    buenosDias: '7:00',
    primeraSiesta: '10:00 - 11:00 - Siesta 1',
    segundaSiesta: '14:00 - 16:00 - Siesta 2',
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  15: {
    buenosDias: '7:00',
    primeraSiesta: '10:00 - 11:00 - Siesta 1',
    segundaSiesta: '14:00 - 16:00 - Siesta 2',
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  16: {
    buenosDias: '7:00',
    primeraSiesta: '13:00 - 15:00 - Siesta 1',
    segundaSiesta: null,
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  17: {
    buenosDias: '7:00',
    primeraSiesta: '13:00 - 15:00 - Siesta 1',
    segundaSiesta: null,
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  18: {
    buenosDias: '7:00',
    primeraSiesta: '13:00 - 15:00 - Siesta 1',
    segundaSiesta: null,
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  19: {
    buenosDias: '7:00',
    primeraSiesta: '13:00 - 15:00 - Siesta 1',
    segundaSiesta: null,
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  20: {
    buenosDias: '7:00',
    primeraSiesta: '13:00 - 15:00 - Siesta 1',
    segundaSiesta: null,
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  21: {
    buenosDias: '7:00',
    primeraSiesta: '13:00 - 15:00 - Siesta 1',
    segundaSiesta: null,
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  22: {
    buenosDias: '7:00',
    primeraSiesta: '13:00 - 15:00 - Siesta 1',
    segundaSiesta: null,
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  23: {
    buenosDias: '7:00',
    primeraSiesta: '13:00 - 15:00 - Siesta 1',
    segundaSiesta: null,
    terceraSiesta: null,
    dormido: '20:00 - Dormido',
  },
  24: {
    buenosDias: '7:00',
    primeraSiesta: '13:00 - 15:00 - Siesta 1',
    segundaSiesta: null,
    terceraSiesta: null,
    dormido: '20:30 - Dormido',
  },
};
