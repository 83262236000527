import fetchCall from './ComunicationService';

const UploadService = () => {
  const getSignedUrl = (filename) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http('services/service-upload-url', {}, headers, 'POST', filename)
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  const deleteServiceFile = (data) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http('services/remove-file', {}, headers, 'POST', data)
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  const makePublic = (data) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http('services/public-file', {}, headers, 'POST', data)
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  return { getSignedUrl, deleteServiceFile, makePublic };
};

export default UploadService();
