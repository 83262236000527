import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import WithValidToken from '../../common/hoc/WithValidToken';
import Spinner from '../../common/components/Spinner';
import UsersService from '../../Services/UsersService';
import './Users.css';
const Users = (props) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [formState, setFormState] = useState({
    values: {},
  });

  useEffect(() => {
    UsersService.getUsers().then((response) => {
      setUsers(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (formState.values.term && formState.values.value) {
      UsersService.getUsers(formState.values.term, formState.values.value).then(
        (response) => {
          setUsers(response);
          setLoading(false);
        }
      );
    }
  }, [formState.values]);

  const handleNewUser = () => {};

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);

      UsersService.getUsers(
        formState.values.term,
        formState.values.value,
        page - 1
      ).then((response) => {
        setUsers(response);
        setLoading(false);
      });
    }
  };

  const handleNextPage = () => {
    if (page >= 0 && page < users.pages) {
      setPage(page + 1);
      UsersService.getUsers(
        formState.values.term,
        formState.values.value,
        page + 1
      ).then((response) => {
        setUsers(response);
        setLoading(false);
      });
    }
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleClick = (event) => {
    if (window.confirm('Enviar Contraseña a: ' + event.target.dataset.email)) {
      UsersService.requestNewPassword(
        event.target.dataset.id
      ).then((response) => console.log(response));
    }
  };

  const renderUsers = () => {
    if (users.length === 0) {
      return;
    }

    return users.users.map((user) => (
      <tr key={users._id}>
        <td>
          <Link to={`/users/edit/${user._id}`} className="link">
            {user.email}
          </Link>
        </td>
        <td>
          <Link to={`/users/edit/${user._id}`} className="link">
            {user.name}
          </Link>
        </td>
        <td>
          <button
            data-id={user._id}
            data-email={user.email}
            className="button-success"
            onClick={handleClick}
          >
            contraseña
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <div className="users-container">
      <div className="title">
        <h1>Usuarios</h1>
        <button
          className="circle-button add-services"
          title="agregar nuevo usuarios"
          onClick={handleNewUser}
        >
          +
        </button>
      </div>
      <div>
        <form className="search-form">
          <select name="term" onChange={handleChange}>
            <option value="" disabled selected hidden>
              Buscar por...
            </option>
            <option value="email">email</option>
            <option value="name">nombre</option>
          </select>
          <input
            id="value"
            name="value"
            type="text"
            defaultValue=""
            placeholder="Termino de busqueda"
            onChange={handleChange}
          />
        </form>
      </div>
      {loading && <Spinner />}
      {!loading && (
        <div className="table-container">
          <table className="payment-table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Nombre</th>
                <th>Enviar Contraseña</th>
              </tr>
            </thead>
            <tbody>{renderUsers()}</tbody>
          </table>
        </div>
      )}
      <div className="paginator-container">
        <button className="circle-button" onClick={handlePreviousPage}>
          {'<'}
        </button>
        <button className="circle-button" onClick={handleNextPage}>
          {'>'}
        </button>
      </div>
    </div>
  );
};

export default WithValidToken(Users);
