import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import WithValidToken from '../../common/hoc/WithValidToken';
import CouponsService from '../../Services/CouponsService';
import Spinner from '../../common/components/Spinner';
import './coupons.css';

const Coupons = (props) => {
  const history = useHistory();
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    CouponsService.getCoupons().then((response) => {
      setCoupons(response);
      setLoading(false);
    });
  }, []);

  const renderCoupons = () => {
    if (coupons.length === 0) {
      return;
    }

    return coupons.map((coupon) => (
      <div key={coupon._id} className="item">
        <Link to={`/coupons/${coupon._id}`} className="link">
          {coupon.coupon}
        </Link>
        <button
          className="circle-button delete-service"
          onClick={handleDelete}
          data-id={coupon._id}
          data-name={coupon.coupon}
        >
          &times;
        </button>
      </div>
    ));
  };

  const handleDelete = (e) => {
    const id = e.target.dataset.id;
    if (
      window.confirm(`Esta seguro que desea borrar: ${e.target.dataset.name}`)
    ) {
      CouponsService.deleteCoupon(id).then(() => {
        const updatedCoupons = coupons.filter((coupon) => coupon._id !== id);
        setCoupons(updatedCoupons);
      });
    }
  };

  const handleNewCoupon = (event) => {
    history.push('/coupons/new');
  };

  return (
    <div className="services-container">
      <div className="title">
        <h1>Cupones</h1>
        <button
          className="circle-button add-services"
          title="agregar nuevo servicio"
          onClick={handleNewCoupon}
        >
          +
        </button>
      </div>
      {loading && <Spinner />}
      {!loading && renderCoupons()}
    </div>
  );
};

export default WithValidToken(Coupons);
