import fetchCall from './ComunicationService';

const PublicService = () => {
  const checker = (token) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http('public/checker', {}, headers, 'POST', {})
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  };

  return {
    checker,
  };
};

export default PublicService();
