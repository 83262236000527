import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import validate from 'validate.js';
import ServicesService from '../../Services/ServicesService';
import ScheduleService from '../../Services/ScheduleService';
import WithValidToken from '../../common/hoc/WithValidToken';
import Notification from '../../common/components/Notification';
import NEW_SCHEMA from './NewSchema';
import './new.css';

const New = () => {
  const history = useHistory();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    ServicesService.getServices().then((response) => {
      setServices(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, NEW_SCHEMA);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleCancel = (event) => {
    history.push('/services');
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : !event.target.files
            ? event.target.value
            : event.target.files[0],
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formState.isValid) {
      return;
    }

    const data = {
      service: formState.values.service,
      bookedDateTime: formState.values.bookedDateTime,
    };

    await ScheduleService.addSchedule(data)
      .then(() => {
        history.push(`/schedules`);
      })
      .catch(() => setShowError(true));
  };

  const renderServices = () => {
    if (services.length === 0) {
      return;
    }

    return services
      .filter((service) => service.service_type !== 'streaming')
      .map((service) => <option value={service._id}>{service.name}</option>);
  };

  return (
    <div className="services-container">
      <div className="title">
        <h1>Nueva Disponibilidad</h1>
      </div>
      <form className="new-form" onSubmit={handleSubmit}>
        <div className="form-field">
          <label htmlFor="service">
            <b>Seleccione un Servicio</b>
          </label>
          <select
            id="service"
            name="service"
            onChange={handleChange}
            defaultValue=""
          >
            <option value="" disabled hidden>
              Seleccione un Servicio...
            </option>
            {renderServices()}
          </select>
        </div>
        <div className="form-field">
          <label htmlFor="bookedDateTime">
            <b>Indique Fecha y Hora</b>
          </label>
          <input
            type="datetime-local"
            placeholder="Indique Fecha y Hora"
            name="bookedDateTime"
            id="bookedDateTime"
            onChange={handleChange}
          />
          {hasError('category') && (
            <span className="field-error">Caterogia es requerido</span>
          )}
        </div>
        <div className="buttons-container">
          <div className="cancel-button">
            <button className="button-error" onClick={handleCancel}>
              Cancelar
            </button>
          </div>
          <div className="save-button">
            <button className="button-success" onClick={handleSubmit}>
              Guardar
            </button>
          </div>
        </div>
        {showError && (
          <Notification
            severity="error"
            text="Ya existe un servicio con esta disponibilidad ☹️"
          />
        )}
      </form>
    </div>
  );
};

export default WithValidToken(New);
