import XLSX from 'xlsx';

const genetateExcelFile = (items, fileName) => {
  const dataWS = XLSX.utils.json_to_sheet(items);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, dataWS, fileName);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export { genetateExcelFile };
