import React from 'react';
import { HtmlEditor, MenuBar } from '@aeaton/react-prosemirror';
import { options, menu } from '@aeaton/react-prosemirror-config-default';

const Streaming = (props) => {
  const { formState, handleHtmlEditor, hasError, handleChangeOther } = props;

  const year = new Date().getFullYear;

  const showZoomEditor = () => {
    let value = '';
    if (
      formState.values.streaming_course &&
      formState.values.streaming_course.zoom_info
    ) {
      value = formState.values.streaming_course.zoom_info;
    }

    return (
      <HtmlEditor
        name="streaming_course.zoom_info"
        options={options}
        value={value}
        onChange={(v) => handleHtmlEditor(v, 'streaming_course.zoom_info')}
        render={({ editor, view }) => (
          <div id="1">
            <MenuBar menu={menu} view={view} />
            {editor}
          </div>
        )}
      />
    );
  };

  const showDescriptionEditor = () => {
    let value = '';
    if (
      formState.values.streaming_course &&
      formState.values.streaming_course.description
    ) {
      value = formState.values.streaming_course.description;
    }

    return (
      <HtmlEditor
        name="streaming_course.description"
        options={options}
        value={value}
        onChange={(v) => handleHtmlEditor(v, 'streaming_course.description')}
        render={({ editor, view }) => (
          <div id="1">
            <MenuBar menu={menu} view={view} />
            {editor}
          </div>
        )}
      />
    );
  };

  return (
    <>
      <div className="form-field">
        <label htmlFor="streaming_course.available_seats">
          <b>
            Plazas Totales <sup>Maximo numero de plazas a vender</sup>
          </b>
        </label>
        <input
          type="number"
          placeholder="Plazas Totales"
          name="streaming_course.available_seats"
          onChange={handleChangeOther}
          required
          defaultValue={
            formState.values.streaming_course
              ? formState.values.streaming_course.available_seats
              : 0
          }
        />
      </div>
      <div className="form-field">
        <label htmlFor="streaming_course.remaining_seats">
          <b>
            Plazas Restantes <sup>Total plazas disponibles</sup>
          </b>
        </label>
        <input
          type="number"
          placeholder="Plazas Restantes"
          name="streaming_course.remaining_seats"
          onChange={handleChangeOther}
          required
          defaultValue={
            formState.values.streaming_course
              ? formState.values.streaming_course.remaining_seats
              : 0
          }
        />
      </div>
      <div className="form-field">
        <label htmlFor="description">
          <b>Descripción</b>
        </label>
        <div className="html-textarea-wrapper">{showDescriptionEditor()}</div>
        {hasError('description') && (
          <span className="field-error">resumen es requerido</span>
        )}
      </div>
      <div className="form-field">
        <label htmlFor="description">
          <b>Información Zoom</b>
        </label>
        <div className="html-textarea-wrapper">{showZoomEditor()}</div>
      </div>
      <div className="form-field">
        <label htmlFor="streaming_course.url">
          <b>
            URL Video <sup>Para verlo offline</sup>
          </b>
        </label>
        <input
          type="text"
          placeholder="URL Video"
          name="streaming_course.url"
          onChange={handleChangeOther}
          defaultValue={
            formState.values.streaming_course
              ? formState.values.streaming_course.url
              : ''
          }
        />
      </div>
      <div className="form-field">
        <label>
          <input
            type="checkbox"
            id="streaming_course.streaming_show_video"
            name="streaming_course.streaming_show_video"
            onChange={handleChangeOther}
            defaultChecked={
              formState.values.streaming_course
                ? formState.values.streaming_course.streaming_show_video
                : false
            }
          />
          Mostrar Video al Usuario
        </label>
        <br />
      </div>
      <div className="form-field">
        <label htmlFor="streaming_course.streaming_date">
          <b>
            Fecha <sup>El día que se realizara el webinar</sup>
          </b>
        </label>
        <br />
        <input
          type="date"
          placeholder="Fecha"
          name="streaming_course.streaming_date"
          onChange={handleChangeOther}
          defaultValue={
            formState.values.streaming_course
              ? formState.values.streaming_course.streaming_date
              : `${year}-07-22`
          }
        />
      </div>
      <div className="form-field">
        <label htmlFor="streaming_course.streaming_date">
          <b>
            Hora <sup>La hora en la que se realizara el webinar</sup>
          </b>
        </label>
        <br />
        <input
          type="time"
          placeholder="Hora"
          name="streaming_course.streaming_hour"
          onChange={handleChangeOther}
          defaultValue={
            formState.values.streaming_course
              ? formState.values.streaming_course.streaming_hour
              : '12-00'
          }
        />
      </div>
      <div className="form-field">
        <label htmlFor="streaming_course.streaming_duration">
          <b>Duración del Webinar</b>
        </label>
        <input
          type="text"
          placeholder="Duración del Servicio"
          name="streaming_course.streaming_duration"
          onChange={handleChangeOther}
          defaultValue={
            formState.values.streaming_course
              ? formState.values.streaming_course.streaming_duration || ''
              : ''
          }
        />
        {hasError('professional_services.duration') && (
          <span className="field-error">
            Duración del servicio es requerido
          </span>
        )}
      </div>
    </>
  );
};

export default Streaming;
