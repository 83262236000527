import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Main from '../common/Layouts/Main';
import PrivateRoute from '../common/components/WithPrivateRoute';
import Login from './Login';
import Dashboard from './Dashboard';
import {
  Services,
  New as NewServices,
  Edit as EditServices,
  Service as ServiceDetails,
} from './Services';
import { Messages, Message } from './Messages';

import { Users, Edit as EditUser } from './Users';
import { Payments } from './Payments';
import { Invoices } from './Invoices';
import { Schedules, New as NewSchedule } from './Schedule';
import Password from './Password';
import Logout from './Logout';
import Generator from './Generator';
import Contract from './Contract';
import { Coupons, New as NewCoupons, Edit as EditCoupon } from './Coupons';

const Routes = () => {
  return (
    <Router>
      <Route exact path="/">
        <Login />
      </Route>
      <PrivateRoute exact path="/dashboard">
        <Main>
          <Payments />
        </Main>
      </PrivateRoute>
      {/** Services */}
      <PrivateRoute exact path="/services">
        <Main>
          <Services />
        </Main>
      </PrivateRoute>
      <PrivateRoute exact path="/services/new">
        <Main>
          <NewServices />
        </Main>
      </PrivateRoute>
      <PrivateRoute exact path="/review/:id">
        <Main>
          <ServiceDetails />
        </Main>
      </PrivateRoute>
      <PrivateRoute exact path="/services/edit/:id">
        <Main>
          <EditServices />
        </Main>
      </PrivateRoute>
      {/** Users */}
      <PrivateRoute exact path="/users">
        <Main>
          <Users />
        </Main>
      </PrivateRoute>
      <PrivateRoute exact path="/users/edit/:id">
        <Main>
          <EditUser />
        </Main>
      </PrivateRoute>

      {/** Messages */}
      <PrivateRoute exact path="/messages">
        <Main>
          <Messages />
        </Main>
      </PrivateRoute>

      <PrivateRoute exact path="/messages/:id">
        <Main>
          <Message />
        </Main>
      </PrivateRoute>

      {/** Payments */}
      <PrivateRoute exact path="/payments">
        <Main>
          <Payments />
        </Main>
      </PrivateRoute>

      {/** Invoices */}
      <PrivateRoute exact path="/invoices">
        <Main>
          <Invoices />
        </Main>
      </PrivateRoute>

      {/** Coupons */}
      <Switch>
        <PrivateRoute exact path="/coupons">
          <Main>
            <Coupons />
          </Main>
        </PrivateRoute>
        <PrivateRoute exact path="/coupons/new">
          <Main>
            <NewCoupons />
          </Main>
        </PrivateRoute>
        <PrivateRoute exact path="/coupons/:id">
          <Main>
            <EditCoupon />
          </Main>
        </PrivateRoute>
      </Switch>

      {/** Schedule */}
      <PrivateRoute exact path="/schedules">
        <Main>
          <Schedules />
        </Main>
      </PrivateRoute>
      <PrivateRoute exact path="/schedules/new">
        <Main>
          <NewSchedule />
        </Main>
      </PrivateRoute>

      {/** Generador Plan Sueño */}
      <PrivateRoute exact path="/plan">
        <Main>
          <Generator />
        </Main>
      </PrivateRoute>

      {/** Contract */}
      <PrivateRoute exact path="/contract/:id">
        <Main>
          <Contract />
        </Main>
      </PrivateRoute>

      <PrivateRoute exact path="/change-password">
        <Main>
          <Password />
        </Main>
      </PrivateRoute>
      <PrivateRoute exact path="/logout">
        <Main>
          <Logout />
        </Main>
      </PrivateRoute>
    </Router>
  );
};

export default Routes;
