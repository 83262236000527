import React from 'react';
import { HtmlEditor, MenuBar } from '@aeaton/react-prosemirror';
import { options, menu } from '@aeaton/react-prosemirror-config-default';

const Professional = (props) => {
  const { formState, handleHtmlEditor, hasError, handleChangeOther } = props;

  return (
    <>
      <div className="form-field">
        <label htmlFor="professional_services.available_seats">
          <b>
            Plazas Totales <sup>Maximo numero de plazas a vender</sup>
          </b>
        </label>
        <input
          type="number"
          placeholder="Plazas Totales"
          name="professional_services.available_seats"
          onChange={handleChangeOther}
          defaultValue={
            formState.values.professional_services &&
            formState.values.professional_services.available_seats
          }
        />
      </div>
      <div className="form-field">
        <label htmlFor="professional_services.remaining_seats">
          <b>
            Plazas Restantes <sup>Total plazas disponibles</sup>
          </b>
        </label>
        <input
          type="number"
          placeholder="Plazas Restantes"
          name="professional_services.remaining_seats"
          onChange={handleChangeOther}
          defaultValue={
            formState.values.professional_services &&
            formState.values.professional_services.remaining_seats
          }
        />
      </div>
      <div className="form-field">
        <label htmlFor="description">
          <b>Descripción</b>
        </label>
        <div className="html-textarea-wrapper">
          {formState.values.professional_services &&
            formState.values.professional_services.description && (
              <HtmlEditor
                name="summary"
                options={options}
                value={formState.values.professional_services.description}
                onChange={(v) =>
                  handleHtmlEditor(v, 'professional_services.description')
                }
                render={({ editor, view }) => (
                  <div>
                    <MenuBar menu={menu} view={view} />
                    {editor}
                  </div>
                )}
              />
            )}
          {!formState.values.professional_services && (
            <HtmlEditor
              name="summary"
              options={options}
              value={''}
              onChange={(v) =>
                handleHtmlEditor(v, 'professional_services.description')
              }
              render={({ editor, view }) => (
                <div>
                  <MenuBar menu={menu} view={view} />
                  {editor}
                </div>
              )}
            />
          )}
        </div>
        {hasError('description') && (
          <span className="field-error">resumen es requerido</span>
        )}
      </div>
      <div className="form-field">
        <label htmlFor="professional_services.duration">
          <b>Duración del Servicio</b>
        </label>
        <input
          type="text"
          placeholder="Duración del Servicio"
          name="professional_services.duration"
          onChange={handleChangeOther}
          defaultValue={
            formState.values.professional_services
              ? formState.values.professional_services.duration || ''
              : ''
          }
        />
        {hasError('professional_services.duration') && (
          <span className="field-error">
            Duración del servicio es requerido
          </span>
        )}
      </div>
    </>
  );
};

export default Professional;
