import React from 'react';
import ServicesService from '../../../Services/ServicesService';
import './gcuploader.css';

const GCUploader = (props) => {
  const { serviceName, totalDocuments, setDocuments } = props;

  const handleDocument = async (e) => {
    const file = e.target.files[0];
    const filename = `${serviceName.split(' ').join('-').toLowerCase()}-${
      totalDocuments.length + 1
    }.${e.target.files[0].name.split('.')[1]}`;

    const data = new FormData();
    data.append('filename', file, filename);

    await ServicesService.getServiceUploadUrl({
      filename: filename,
    }).then((url) => {
      fetch(url.signed_url, {
        method: 'put',
        body: data,
        headers: { 'Content-Type': 'application/octet-stream' },
      }).then(() => setDocuments([...totalDocuments, `${filename}`]));
    });
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    const file = e.target.dataset.file;
    window.open(
      `https://storage.googleapis.com/mimate-services/${file}`,
      '_blank'
    );
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    const filename = e.target.dataset.file;
    await ServicesService.deleteFileFromGCloud({ filename }).then(() => {
      const updateFiles = totalDocuments.filter((file) => file !== filename);
      setDocuments(updateFiles);
    });
  };

  const renderDocuments = () => {
    return totalDocuments.map((file, index) => (
      <div className="document-container" key={index}>
        <div role="button" onClick={handleOpen} data-file={file}>
          <span className="close" onClick={handleDelete} data-file={file}>
            &times;
          </span>
          <span role="img" aria-label={file} data-file={file}>
            📎
          </span>
          <p className="document-title" data-file={file}>
            {file}
          </p>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <div className="field">
        <div className="control">
          <input
            type="file"
            name="fileToUpload"
            id="fileToUpload"
            className="input"
            onChange={handleDocument}
            required={false}
          />
        </div>
      </div>
      <div className="field">
        <div className="documents-container">{renderDocuments()}</div>
      </div>
    </div>
  );
};

export default GCUploader;
