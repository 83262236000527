import fetchCall from "./ComunicationService";

const ContractsService = () => {
  const getContracts = (term = null, value = null, page = 1) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const url = term
      ? `contracts/payments?term=${term}&value=${value}&page=${page}`
      : page !== 1
        ? `contracts/payments?page=${page}`
        : "contracts/payments";
    return fetchCall
      .http(url, {}, headers, "GET", {})
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  const getPayments = (from, to) => {
    const data = { from, to };
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return fetchCall
      .http("contracts/invoices", {}, headers, "POST", data)
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  const getLatestPayments = () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return fetchCall
      .http("contracts/latest-contracts", {}, headers, "GET", {})
      .then((response) => response.contracts)
      .catch((error) => console.log(error));
  };

  const markAsCompleted = (id) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return fetchCall
      .http(`contracts/marked-contacted/${id}`, {}, headers, "GET", {})
      .then((response) => response.contracts)
      .catch((error) => console.log(error));
  };

  const getPendingMessages = () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return fetchCall
      .http("contracts/all-messages", {}, headers, "GET", {})
      .then((response) => response.messages)
      .catch((error) => console.log(error));
  };

  const getContractMessages = (contract) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return fetchCall
      .http(`contracts/messages/${contract}`, {}, headers, "GET", {})
      .then((response) => response.messages)
      .catch((error) => {
        throw new Error(error);
      });
  };

  const addContractMessage = (data) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return fetchCall
      .http(`contracts/messages/${data.contractId}`, {}, headers, "POST", data)
      .then((response) => response.messages)
      .catch((error) => {
        throw new error();
      });
  };

  const updateContractMessage = (data) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return fetchCall
      .http(`contracts/messages/${data.contractId}`, {}, headers, "PUT", data)
      .then((response) => response.messages)
      .catch((error) => {
        throw new error();
      });
  };

  const getUserContract = (id) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return fetchCall
      .http(`contracts/user-contract/${id}`, {}, headers, "GET", {})
      .then((response) => response.contract)
      .catch((error) => {
        throw new Error(error);
      });
  };

  const saveContractDates = (data) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return fetchCall
      .http(
        `contracts/contract-dates/${data.contractId}`,
        {},
        headers,
        "PUT",
        data,
      )
      .then((response) => response.contract)
      .catch((error) => {
        throw new error();
      });
  };
  const getContractUserInfo = ({ term, value }) => {
    if (value === '') {
      return [];
    }
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return fetchCall
      .http(
        `contracts/payments?term=${term}&value=${value}`,
        {},
        headers,
        "GET",
      )
      .then((response) => response.contracts)
      .catch((error) => {
        throw new error();
      });
  };

  const saveNewInvoice = (data) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return fetchCall
      .http("contracts/save-invoice", {}, headers, "POST", data)
      .then((response) => response)
      .catch((error) => console.log(error));
  }

  return {
    getContracts,
    getUserContract,
    getPayments,
    getLatestPayments,
    markAsCompleted,
    getPendingMessages,
    getContractMessages,
    addContractMessage,
    saveContractDates,
    updateContractMessage,
    getContractUserInfo,
    saveNewInvoice
  };
};

export default ContractsService();
