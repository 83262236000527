import React, { useEffect, useState } from "react";
import {
  Document,
  Font,
  Page,
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import WithValidToken from "../../common/hoc/WithValidToken";
import Spinner from "../../common/components/Spinner";
import ContractsService from "../../Services/ContractsService";
import "./Invoices.css";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: 20,
  },
  title: {
    fontSize: 16,
    backgroundColor: "#E4E4E4",
  },
  subtitle: {
    fontSize: 12,
  },
  columnTitle: {
    fontSize: 12,
    fontWeight: "bold",
    backgroundColor: "#E4E4E4",
    textAlign: "center",
    borderTop: "1px solid #000",
    borderBottom: "1px solid #000",
  },
  columnItem: {
    fontSize: 10,
    textAlign: "center",
    borderBottom: "1px solid #d3d3d3",
  },
  columnBorder: {
    borderRight: "1px solid #d3d3d3",
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
  },
  details: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignContent: "stretch",
    alignItems: "flex-start",
  },
  firstColumn: {
    order: 0,
    flex: "0 1 10%",
    alignSelf: "auto",
  },
  secondColumn: {
    order: 0,
    flex: "0 1 70%",
    alignSelf: "auto",
  },
  thirdColumn: {
    order: 0,
    flex: "0 1 10%",
    alignSelf: "auto",
  },
  fourthColumn: {
    order: 0,
    flex: "0 1 10%",
    alignSelf: "auto",
  },
  alignRight: {
    textAlign: "right",
  },
  redColor: {
    color: "red",
  },
  viewer: {
    width: "100%",
    minHeight: 1200,
  },
});

const Invoices = (props) => {
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState({
    from: null,
    to: null,
  });
  const [invoices, setInvoices] = useState([]);

  const handleDatesChange = (event) => {
    event.persist();
    setDates((formState) => ({
      ...formState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleGenerateInvoice = () => {
    setLoading(true);
    ContractsService.getPayments(dates.from, dates.to).then((response) => {
      console.log(response.contracts);
      setInvoices(response.contracts);
      setLoading(false);
    });
  };

  const MyDoc = () => (
    <Document>
      {invoices.map((invoice) => {
        let itemValue = invoice.stripe_amount / 100;
        let irpfAmount = 0;
        let ivaValue = itemValue * 0.21;
        let coupon = null;
        let discountAmount = 0;
        let irpf15Retention = false;
        if (invoice.coupon) {
          coupon = invoice.coupon;
          discountAmount =
            invoice.original_amount / 100 - invoice.amount_with_discount / 100;
          itemValue = invoice.original_amount / 100;
        }
        if (invoice.irpf15Retention) {
          irpf15Retention = invoice.irpf15Retention;
          irpfAmount = invoice.irpf15Amount;
        }

        if (invoice.service_id.iva21) {
          itemValue = itemValue - ivaValue;
        }

        // 60 - 12,6 = 47,4
        // descuento antes del iva 47,4 - 15% (9,954) = 47,4 - 9,954 = 37,446 + IVA (7,86) = 45,30 €
        // descuento si no tienen ivan 60 - 15% (9) = 51€
        return (
          <Page size="A4" style={styles.page} key={invoice.dni}>
            <View style={styles.section}>
              <View style={styles.column}>
                <Text style={styles.title}>
                  Factura{" "}
                  {invoice.service_id.category === "Taller" ||
                  invoice.service_id.service_type === "streaming"
                    ? `W/${invoice.invoice_number}`
                    : invoice.invoice_number}
                </Text>
                <Text style={styles.subtitle}>&nbsp;</Text>
                <Text style={styles.subtitle}>Alba Rojas Dosta</Text>
                <Text style={styles.subtitle}>DNI: 40562799E</Text>
                <Text style={styles.subtitle}>Carrer Provença 238</Text>
                <Text style={styles.subtitle}>08008 Barcelona</Text>
                <Text style={styles.subtitle}>Tel: 619403186</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.title}>&nbsp;</Text>
                <Text style={styles.subtitle}>&nbsp;</Text>
                <Text style={styles.subtitle}>
                  Data:{" "}
                  {new Intl.DateTimeFormat("es-ES").format(
                    new Date(invoice.created_at),
                  )}
                </Text>
                <Text style={styles.subtitle}>Nombre: {invoice.name}</Text>
                <Text style={styles.subtitle}>
                  C.I.F./N.I.F.: {invoice.dni}
                </Text>
              </View>
            </View>
            <View style={styles.details}>
              <View style={styles.firstColumn}>
                <Text>&nbsp;</Text>
                <Text
                  style={{
                    ...styles.columnTitle,
                    ...styles.columnBorder,
                  }}
                >
                  Cantidad
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  1
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
              </View>
              <View style={styles.secondColumn}>
                <Text>&nbsp;</Text>
                <Text
                  style={{
                    ...styles.columnTitle,
                    ...styles.columnBorder,
                  }}
                >
                  Descripción
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  {invoice.service_id.name}
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  {irpf15Retention ? (
                    "ES86 2100 3038 1022 0086 2096"
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text
                  style={{
                    ...styles.columnItem,
                    ...styles.columnBorder,
                    ...styles.alignRight,
                  }}
                >
                  Subtotal
                </Text>
                <Text
                  style={{
                    ...styles.columnItem,
                    ...styles.columnBorder,
                    ...styles.alignRight,
                  }}
                >
                  {coupon ? `Descuento campaña: ${coupon}` : <div>&nbsp;</div>}
                  {irpf15Retention ? "IRPF 15%" : <div>&nbsp;</div>}
                </Text>
                <Text
                  style={{
                    ...styles.columnItem,
                    ...styles.columnBorder,
                    ...styles.alignRight,
                  }}
                >
                  {invoice.service_id.iva21
                    ? "IVA 21%"
                    : "Factura exenta de IVA (articulo 20.Uno.8º b) -Ley 37/1992"}
                </Text>
                <Text
                  style={{
                    ...styles.columnItem,
                    ...styles.columnBorder,
                    ...styles.alignRight,
                  }}
                >
                  Total
                </Text>
              </View>
              <View style={styles.thirdColumn}>
                <Text>&nbsp;</Text>
                <Text
                  style={{
                    ...styles.columnTitle,
                    ...styles.columnBorder,
                  }}
                >
                  Precio/€
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  {new Intl.NumberFormat("es-ES", {
                    style: "currency",
                    currency: "EUR",
                  }).format(itemValue)}
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.columnBorder }}>
                  &nbsp;
                </Text>
              </View>
              <View style={styles.fourthColumn}>
                <Text>&nbsp;</Text>
                <Text style={styles.columnTitle}>Total</Text>
                <Text style={styles.columnItem}>
                  {new Intl.NumberFormat("es-ES", {
                    style: "currency",
                    currency: "EUR",
                  }).format(itemValue)}
                </Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>&nbsp;</Text>
                <Text style={styles.columnItem}>
                  {new Intl.NumberFormat("es-ES", {
                    style: "currency",
                    currency: "EUR",
                  }).format(
                    irpf15Retention ? invoice.stripe_amount / 100 : itemValue,
                  )}
                </Text>
                <Text style={styles.columnItem}>
                  {coupon ? (
                    "-" +
                    new Intl.NumberFormat("es-ES", {
                      style: "currency",
                      currency: "EUR",
                    }).format(discountAmount)
                  ) : irpf15Retention ? (
                    "-" +
                    new Intl.NumberFormat("es-ES", {
                      style: "currency",
                      currency: "EUR",
                    }).format(irpfAmount)
                  ) : (
                    <div>&nbsp;</div>
                  )}
                </Text>
                <Text style={styles.columnItem}>
                  {invoice.service_id.iva21 ? (
                    new Intl.NumberFormat("es-ES", {
                      style: "currency",
                      currency: "EUR",
                    }).format(ivaValue)
                  ) : (
                    <div>&nbsp;</div>
                  )}
                </Text>
                <Text style={{ ...styles.columnItem, ...styles.redColor }}>
                  {new Intl.NumberFormat("es-ES", {
                    style: "currency",
                    currency: "EUR",
                  }).format(
                    irpf15Retention
                      ? itemValue - itemValue * 0.15
                      : invoice.stripe_amount / 100,
                  )}
                </Text>
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  );

  return (
    <div className="users-container" style={{ paddingRight: "20px" }}>
      <div className="title">
        <h1>Facturas</h1>
      </div>
      <div className="forms-container">
        <form className="excel-invoice">
          <div className="form-field">
            <label htmlFor="streaming_course.streaming_date">
              <b>Desde</b>
            </label>
            <br />
            <input
              type="date"
              placeholder="Fecha"
              name="from"
              onChange={handleDatesChange}
            />
          </div>
          <div className="form-field">
            <label htmlFor="streaming_course.streaming_date">
              <b>Hasta</b>
            </label>
            <br />
            <input
              type="date"
              placeholder="Fecha"
              name="to"
              onChange={handleDatesChange}
            />
          </div>
          <div className="form-field">
            <button
              type="button"
              className="button"
              onClick={handleGenerateInvoice}
            >
              Generar Facturas
            </button>
          </div>
        </form>
      </div>
      {loading && <Spinner />}
      {!loading && (
        <div>
          <PDFDownloadLink document={<MyDoc />} fileName="somename.pdf">
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Descargar Facturas"
            }
          </PDFDownloadLink>
          <br />
          {invoices.length > 0 && (
            <PDFViewer style={styles.viewer}>
              <MyDoc />
            </PDFViewer>
          )}
          {/* {invoices.length > 0 && <MyDoc />} */}
        </div>
      )}
    </div>
  );
};

export default WithValidToken(Invoices);
