import React from 'react';
import Modal from '../../common/components/Modal';

const DashboardModal = (props) => {
  const {
    showModal,
    setShowModal,
    userContractName,
    handleChange,
    formState,
    handleSubmitTime,
  } = props;
  return (
    <Modal show={showModal} setShowModal={setShowModal}>
      <br />
      <br />
      <div>
        Modificar la fecha del servicio de: {userContractName}
        <form>
          <label htmlFor="start_date">Fecha Inicio:</label>
          <input
            type="date"
            id="start_date"
            name="start_date"
            onChange={handleChange}
            defaultValue={formState.values.start_date}
          />
          <label htmlFor="end_date">Fecha Fin:</label>
          <input
            type="date"
            id="end_date"
            name="end_date"
            onChange={handleChange}
            defaultValue={formState.values.end_date}
          />
          <label htmlFor="next_call_date">Proxima Llamada:</label>
          <input
            type="date"
            id="next_call_date"
            name="next_call_date"
            onChange={handleChange}
            defaultValue={formState.values.next_call_date}
          />
          <label htmlFor="next_call_time">Hora Llamada:</label>
          <input
            type="time"
            id="next_call_time"
            name="next_call_time"
            onChange={handleChange}
            defaultValue={formState.values.next_call_time}
          />
          <br />
          <br />
          <div className="save-button">
            <button className="button-success" onClick={handleSubmitTime}>
              Guardar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default DashboardModal;
