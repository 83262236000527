import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../../../../assets/images/logo.svg';
import './header.css';

const Header = (pops) => {
  return (
    <nav>
      <div className="header-container">
        <Link to="/dashboard">
          <img src={logo} alt="mimate mama" className="logo" />
        </Link>
        <div className="links-container">
          <NavLink
            to="/dashboard"
            className="link"
            activeClassName="link-active"
          >
            Inicio
          </NavLink>
          <NavLink
            to="/services"
            className="link"
            activeClassName="link-active"
          >
            Servicios
          </NavLink>
          <NavLink to="/users" className="link" activeClassName="link-active">
            Usuarios
          </NavLink>
          <NavLink
            to="/messages"
            className="link"
            activeClassName="link-active"
          >
            Mensajes
          </NavLink>
          <NavLink
            to="/payments"
            className="link"
            activeClassName="link-active"
          >
            Pagos
          </NavLink>
          <NavLink
            to="/invoices"
            className="link"
            activeClassName="link-active"
          >
            Facturas
          </NavLink>
          <NavLink to="/coupons" className="link" activeClassName="link-active">
            Cupones
          </NavLink>
          <NavLink
            to="/schedules"
            className="link"
            activeClassName="link-active"
          >
            Disponibilidad
          </NavLink>
          <NavLink to="/plan" className="link" activeClassName="link-active">
            Generador
          </NavLink>
          <NavLink
            to="/change-password"
            className="link"
            activeClassName="link-active"
          >
            Contraseña
          </NavLink>
          <NavLink to="/logout" className="link" activeClassName="link-active">
            Salir
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Header;
