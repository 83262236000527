const NEW_SCHEMA = {
  service: {
    presence: { allowEmpty: false },
  },
  bookedDateTime: {
    presence: { allowEmpty: false },
  },
};

export default NEW_SCHEMA;
