import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import validate from 'validate.js';
import ServicesService from '../../Services/ServicesService';
import CouponService from '../../Services/CouponsService';
import WithValidToken from '../../common/hoc/WithValidToken';
import NEW_SCHEMA from './NewSchema';
import styles from './new.module.css';

const New = () => {
  const history = useHistory();
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState({ services: [] });
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      services: [],
      is_precent: false,
      quantity: 0,
    },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    ServicesService.getServices().then((response) => {
      setServices(response);
    });
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, NEW_SCHEMA);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleCancel = (event) => {
    history.push('/coupons');
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : !event.target.files
            ? event.target.value
            : event.target.files[0],
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formState.isValid) {
      return;
    }
    console.log(formState.values);

    const data = new FormData();
    data.append('coupon', formState.values.coupon);
    data.append('amount', formState.values.amount);
    data.append('is_precent', formState.values.is_precent);
    data.append('quantity', formState.values.quantity);
    data.append('start_date', formState.values.start_date);
    data.append('end_date', formState.values.end_date);
    data.append('services', formState.values.services);

    await CouponService.addCoupon(formState.values).then((response) => {
      history.push(`/coupons`);
    });
  };

  const renderSelectedServices = () => {
    if (selectedServices.services.length === 0) {
      return;
    }

    return selectedServices.services.map((service) => (
      <div className={styles.deleteSelectedServices} key={service.id}>
        <button
          className={styles.deleteSelectedServicesBtn}
          data-id={service.id}
          onClick={handleDeleteSelectedServices}
        >
          <span
            role="img"
            aria-label="editar"
            style={{ 'pointer-events': 'none' }}
          >
            <i className="zwicon-trash"></i>
          </span>
        </button>
        {service.name}
      </div>
    ));
  };

  const renderServices = () => {
    if (services.length === 0) {
      return;
    }

    return services.map((service) => (
      <option key={service._id} data-service={service.name} value={service._id}>
        {service.name}
      </option>
    ));
  };

  const handleDeleteSelectedServices = (event) => {
    event.persist();
    const deletedServices = event.target.dataset.id;
    const deleteServiceData = selectedServices.services.filter(
      (service) => service.id === deletedServices
    )[0];

    const deletedServicesValues = formState.values.services.filter(
      (service) => service !== deletedServices
    );

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        services: deletedServicesValues,
      },
    }));

    setServices((services) => [
      ...services,
      { _id: deleteServiceData.id, name: deleteServiceData.name },
    ]);

    const newSeledtedServices = selectedServices.services.filter(
      (service) => service.id !== deletedServices
    );

    setSelectedServices((selectedServices) => ({
      ...selectedServices,
      services: newSeledtedServices,
    }));
  };

  const handleSelectService = (event) => {
    event.persist();
    setSelectedServices((selectedServices) => ({
      services: [
        ...selectedServices.services,
        {
          id: event.target.value,
          name: event.target[event.target.selectedIndex].dataset.service,
        },
      ],
    }));

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        services: [...formState.values.services, event.target.value],
      },
    }));

    const filterServices = services.filter(
      (service) => service._id !== event.target.value
    );
    setServices(filterServices);
  };

  return (
    <div className={styles.couponsContainer}>
      <div className={styles.title}>
        <h1>Nuevo Cupón</h1>
      </div>
      <form className={styles.newForm} onSubmit={handleSubmit}>
        <div className="form-field">
          <label htmlFor="coupon">
            <b>Cupon</b>
          </label>
          <input
            type="text"
            placeholder="Nombre del Cupón"
            name="coupon"
            onChange={handleChange}
            defaultValue={formState.values.coupon || ''}
          />
          {hasError('coupon') && (
            <span className="field-error">Nombre del Cupón es requerido</span>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="amount">
            <b>Monto</b>
          </label>
          <input
            type="number"
            placeholder="Monto"
            name="amount"
            onChange={handleChange}
            defaultValue={formState.values.amount}
          />
          {hasError('amount') && (
            <span className="field-error">Nombre es requerido</span>
          )}
        </div>
        <div className="form-field">
          <label htmlFor="is_precent">
            <b>Es porcentaje</b>
          </label>
          <br />
          <input
            type="checkbox"
            name="is_precent"
            checked={formState.values.show_reservio}
            onChange={handleChange}
          />
          Si
        </div>

        <div className="form-field">
          <label htmlFor="quantity">
            <b>Cantidad Disponible</b>
          </label>
          <input
            type="number"
            placeholder="Cantidad Disponible"
            name="quantity"
            onChange={handleChange}
            defaultValue={formState.values.quantity}
          />
          {hasError('quantity') && (
            <span className="field-error">Nombre es requerido</span>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="start_date">
            <b>Disponible desde:</b>
          </label>
          <input
            type="date"
            placeholder="Disponible desde"
            name="start_date"
            onChange={handleChange}
            defaultValue={formState.values.start_date}
          />
          {hasError('start_date') && (
            <span className="field-error">Nombre es requerido</span>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="end_date">
            <b>Disponible hasta:</b>
          </label>
          <input
            type="date"
            placeholder="Disponible hasta"
            name="end_date"
            onChange={handleChange}
            defaultValue={formState.values.end_date}
          />
          {hasError('end_date') && (
            <span className="field-error">Nombre es requerido</span>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="services">
            <b>Servicios al que aplica</b>
          </label>
          {renderSelectedServices()}
          <select
            name="services"
            onChange={handleSelectService}
            value={formState.values.services}
          >
            <option selected>Selecciona un servicio...</option>

            {renderServices()}
          </select>
        </div>

        <div className="buttons-container">
          <div className="cancel-button">
            <button className="button-error" onClick={handleCancel}>
              Cancelar
            </button>
          </div>
          <div className="save-button">
            <button className="button-success" onClick={handleSubmit}>
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithValidToken(New);
