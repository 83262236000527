const NEW_SCHEMA = {
  name: {
    presence: { allowEmpty: false },
  },
  category: {
    presence: { allowEmpty: false },
  },
  summary: {
    presence: { allowEmpty: false },
  },
  price: {
    presence: { allowEmpty: false },
  },
  expire: {
    presence: { allowEmpty: false },
  },
};

export default NEW_SCHEMA;
