const EDIT_SCHEMA = {
  name: {
    presence: { allowEmpty: false },
  },
  category: {
    presence: { allowEmpty: false },
  },
  summary: {
    presence: { allowEmpty: false },
  },
  price: {
    presence: { allowEmpty: false },
  },
  expire_in: {
    presence: { allowEmpty: false },
  },
};

export default EDIT_SCHEMA;
