import React from 'react';

const DocumentPlan = (props) => {
  const {
    formState,
    singularPlurar,
    tablaSueno,
    tablaHorario,
    lola,
    lele,
    despiertoa,
    soloa,
    ella,
    cansadoa,
    renderSiestas,
    renderRituales,
    renderStartNight,
    renderMiddleNight,
    renderHelpersHorario,
    renderTableSueno,
    renderHorariosSueno,
    dormirloa,
    pechoBiberon,
  } = props;

  const planTitleStyle = {
    fontSize: '18.666666666666664px',
    color: '#e6635a',
    backgroundColor: 'transparent',
    fontWeight: 700,
    fontStyle: 'normal',
    fontVariant: 'normal',
    verticalAlign: 'baseline',
    whiteSpace: 'pre',
    textDecoration: 'underline',
  };

  const redLetterStyle = {
    color: '#ff9696',
  };

  return (
    <>
      <div style={planTitleStyle}>INFORMACIÓN INICIAL</div>
      <ul className="plan-ul">
        <li>
          <strong>
            {formState.values.name} tiene {formState.values.age} meses
          </strong>{' '}
          y actualmente por las noches <strong>se duerme</strong> al inicio de
          la noche
          <strong>
            {' '}
            {renderStartNight()} {renderMiddleNight()}
          </strong>
          .
        </li>
        <li>
          {formState.values.name}{' '}
          <strong>duerme {formState.values.sleepwhere}</strong> todas las noches
          {formState.values.middleNight.includes('pasa a la cama de los padres')
            ? 'y algunas veces pasa a la cama de sus padres.'
            : '.'}
        </li>
        <li>
          <strong>
            Suele hacer {formState.values.totalNapText}{' '}
            {formState.values.totalNap === 'una' ? 'siesta' : 'siestas'}
          </strong>
          . {renderSiestas()}.
        </li>
        <li>
          {formState.values.name}{' '}
          <strong>
            se va a dormir actualmente sobre las {formState.values.bedtime}
          </strong>
          . El ritual empieza sobre las {formState.values.ritualtime} de la
          noche.
        </li>
        <li>
          El <strong>ritual</strong> suele ser{' '}
          <strong>{renderRituales()}.</strong>
        </li>
      </ul>
      <div style={planTitleStyle}>OBJETIVOS A CONSEGUIR</div>
      <ul className="plan-ul">
        <li>
          Conseguir que {formState.values.name} adquiera un sueño autónomo desde
          el inicio de la noche para que{' '}
          <strong>
            aprenda a dormirse{' '}
            {formState.values.gender === 'm' ? 'solito' : 'solita'}
            {singularPlurar} sin necesidad de recurrir{' '}
            {formState.values.startNight
              .join(', ')
              .replace('de', 'a')
              .replace('del', 'al')}
          </strong>
          .
        </li>
        <li>
          Que poco a poco pueda{' '}
          <strong>reducir los despertares nocturnos</strong>. Una vez aprenda a
          dormirse {formState.values.gender === 'm' ? 'solito' : 'solita'}
          {singularPlurar} desde el inicio de la noche, aquellos despertares que
          sean por hábito se irán reduciendo.
        </li>
        <li>
          <strong>Crear una rutina y un ritual acorde a su edad</strong> y que
          le ayude a anticipar las acciones para darle confianza y seguridad y
          sea más fácil la hora de irse a dormir.
        </li>
        <li>
          <strong>Trabajar en las siestas</strong>.
        </li>
        <li>
          <strong>Mejorar la calidad y cantidad de sueño</strong> de{' '}
          {formState.values.name} y de toda la familia.
        </li>
        <li>
          Trabajaremos todos estos puntos siempre utilizando un método gradual
          de acompañamiento basado en el apego y siempre teniendo en cuenta los
          deseos y objetivos de toda la familia. No vamos a dejar a{' '}
          {formState.values.name} {soloa} en ningún momento, siempre estaremos
          con {ella}
          en todo el proceso de aprendizaje.
        </li>
      </ul>
      <div style={planTitleStyle}>RECOMENDACIÓN DE SUEÑO POR EDAD</div>
      <ul className="plan-ul">
        <li>
          {formState.values.name} actualmente tiene {formState.values.age}{' '}
          meses, por lo que las recomendaciones de horas de sueño por edad
          serían las que os anoto a continuación. Igualmente, iremos viendo a
          medida que avancemos el plan de sueño si {ella}
          sigue estas recomendaciones o las iremos variando según sus
          necesidades.
        </li>
      </ul>
      <div className="table-container">{renderTableSueno()}</div>
      <div style={planTitleStyle}>HORARIO</div>
      <ul className="plan-ul">
        <li>
          A continuación os detallo el horario recomendado para{' '}
          {formState.values.name}, siguiendo las recomendaciones de sueño por su
          edad. El horario lo iremos adaptando en el plan de sueño, pero
          iniciaremos con este para ver si{' '}
          <strong>
            conseguimos que {formState.values.name} no llegue sobre {cansadoa}{' '}
            por la noche y así poder trabajar el que se duerma {ella}{' '}
            {formState.values.gender === 'm' ? 'solito' : 'solita'}
            {singularPlurar} {renderHelpersHorario()}.
          </strong>
        </li>
      </ul>
      <div className="table-container">{renderHorariosSueno()}</div>
      <span className="tener">A tener en cuenta:</span>
      <ul className="plan-ul">
        {tablaHorario.segundaSiesta && (
          <li>
            La siesta más importante del día es la segunda, debemos intentar que
            esa sea la más larga y reparadora del día, puesto que es la siesta
            que nuestros peques van a hacer más tiempo (hasta los 3 o 4 años) a
            medida que vayan creciendo.
          </li>
        )}
        {parseInt(formState.values.age) >= 9 &&
        parseInt(formState.values.age) <= 15 ? (
          <li>
            Si hacemos una tercera siesta de emergencia intentaremos que no sea
            más tarde de las 17:00 para que luego no nos afecte al sueño
            nocturno de {formState.values.name}. Despertarla como máximo a las
            17:30. Esa siesta será de máximo 30 minutos y ese día debera estar
            {formState.values.gender === 'm'
              ? singularPlurar === ''
                ? 'dormido'
                : 'dormidos'
              : singularPlurar === ''
              ? 'dormida'
              : 'dormidas'}{' '}
            sobre las 20:30
          </li>
        ) : null}
        {tablaHorario.terceraSiesta && parseInt(formState.values.age) < 9 && (
          <>
            <li>
              La tercera siesta debe ser corta para desconectar, máximo 45
              minutos, para que no llegue tan cansado por la noche.
            </li>
          </>
        )}

        <li>
          A las 20:00 {formState.values.name} debería estar durmiendo, porque
          entre las 19:30 y 20:30 de la noche es cuando segrega más melatonina y
          eso les ayuda en el proceso de irse a dormir.
        </li>
        <li>
          Muy importante{' '}
          <strong>
            que no pase más de{' '}
            {tablaSueno.ventanas && tablaSueno.ventanas.split(' ')[2]} horas
          </strong>{' '}
          (valoraremos si es más tiempo) desde la última siesta a la hora de
          irse a la cama por la noche, para que nos aseguremos que no esté sobre
          cansado y no pase así, su ventana de sueño.
        </li>
      </ul>
      <div style={planTitleStyle}>RUTINA Y RITUAL NOCTURNOS</div>
      <ol>
        <li>
          <strong>Una hora antes de ir a dormir</strong> (aproximadamente sobre
          las 18:30) intentaremos <strong>no utilizar pantallas</strong>, ya que
          la luz que transmiten inhibe la segregación de melatonina y les puede
          dificultar dormirse por la noche.
        </li>
        <li>
          <strong>Sobre las 19:00</strong>, o un poco más tarde o un poco más
          temprano, según os vaya mejor podemos empezar con{' '}
          <strong>{formState.values.ritualDescriptions[0]}</strong>, tal y como
          hacéis ahora.
        </li>
        {formState.values.ritualDescriptions[1] !== undefined && (
          <li>
            <strong>
              Sobre las 19:20 {formState.values.ritualDescriptions[1]}
            </strong>{' '}
            a {formState.values.name}, de momento está tomando {pechoBiberon},
            por lo que nos pondremos en una habitación distinta a la vuestra o
            en el salón para que cene, con luz abierta. Nos aseguraremos que esa
            toma sea completa para que así no estemos preocupados de que no haya
            tomado suficiente.
          </li>
        )}
        <li>
          <strong>
            Una vez que {formState.values.name} haya cenado incorporaremos en su
            rutina
          </strong>
          , un pequeño ritual. Un ritual es una actividad que nos ayuda a que
          nuestros peques entiendan que es hora de ir a dormir. Puede ser
          cualquier actividad que a {ella}
          le relaje y le guste, pero que {lola} haga bajar sus revoluciones.
          Esta actividad la podemos hacer en el salón después de cenar o ya en
          la habitación, como os vaya mejor, pero siempre será en el mismo sitio
          y seguiremos el mismo procedimiento. En este momento, podéis
          participar todos, papá, mamá y {formState.values.name}. Debe ser un
          momento para todos, en el que podáis conectar y compartir tiempo
          juntos antes de ir a dormir.{' '}
          <strong>
            Puede haber {pechoBiberon} si {formState.values.name} lo desea,
            abrazos, mucho cariño, actividades de cuentos y libros, brazos, lo
            que a {ella}
            le{singularPlurar} guste{singularPlurar} y sea un momento de calma
          </strong>
          . También recomiendo que después de la actividad que hagáis, podáis
          poner una alarma de fin de actividad que sea una canción tranquila de
          ir a dormir, para que así {formState.values.name} sepa que después de
          esa canción nos vamos a la cama, podéis también cantarla vosotros.
        </li>
        <li>
          Una vez acabemos la actividad,{' '}
          <strong>
            iremos a la habitación si estábamos en el salón, cerraremos las
            persianas, le daremos las buenas noches a {formState.values.name},
            {lola} abrazaremos, cerraremos la luz y lo pondremos en la cuna{' '}
            {despiertoa}
          </strong>
          .{' '}
          <strong style={redLetterStyle}>
            (ATENTOS: esto lo haremos siguiendo las pautas del plan de sueño,
            pero este sería el objetivo final)
          </strong>
          . La habitación es mejor que esté a oscuras totalmente sin ninguna luz
          quitamiedos por el momento.{' '}
        </li>
      </ol>
      <div style={planTitleStyle}>RUTINA Y RITUAL DIURNO</div>
      <ul className="plan-ul">
        <li>
          No sacaremos a {formState.values.name} de su cuna hasta las 7:00 de la
          mañana, si se despierta a las 6:00 por ejemplo, intentaremos dormirle
          de la forma que os explico a continuación. Si no se duerme, saldremos
          de la habitación, respiramos profundo y entraremos dándole los buenos
          días de forma enérgica, abriendo las persianas, cantando una canción e
          intentando cada día el mismo ritual de BUENOS DÍAS. ¿Porqué? De ese
          modo, si salimos de la habitación le damos el mensaje a{' '}
          {formState.values.name} de que nos despertamos porque es de día y no
          porque se haya estado quejando una hora. Si se está quejando y
          nosotros al cabo de un rato de protesta la sacamos de la cuna, {ella}
          no entenderá porque en algunos momentos si puede salir y en otros no.
        </li>
      </ul>
      <div style={planTitleStyle}>PLAN DE SUEÑO: PROCEDIMIENTO</div>
      <ul className="plan-ul">
        {[
          'en la cama con papá',
          'en la cama con mamá',
          'en la cama con sus padres',
        ].includes(formState.values.sleepwhere) && (
          <>
            <li>
              <span className="tener">
                PRIMERA TOMA DE CONTACTO CON LA CUNA
              </span>
              <ul className="plan-ul">
                <li>
                  {formState.values.name} duerme en la cama con papá desde el
                  inicio de la noche, podemos empezar el plan de sueño, haciendo
                  los mismos pasos que hacéis actualmente:{' '}
                  {formState.values.howSleep}.
                </li>
                <li>
                  Iremos valorando en qué momento ponemos los 4 barandas de la
                  cuna, para que quede montada completamente.
                </li>
                <li>
                  Yo soy partidaria de que desde el inicio de la noche, mamá
                  esté también en la habitación, ya que así{' '}
                  {formState.values.name} irá aprendiendo que tanto mamá, como
                  papá van a dormirle de la misma forma y no habrá distinción.
                  Papá se puede estirar al lado de {formState.values.name} y
                  mamá en el otro lado, por ejemplo.
                </li>
              </ul>
            </li>
          </>
        )}
        {formState.values.objective.includes('habitación') && (
          <>
            <li>
              <span className="tener">ADAPTACIÓN A SU HABITACIÓN</span>
              <ul className="plan-ul">
                <li>
                  En primer lugar, {formState.values.name} dormirá las{' '}
                  <strong>
                    primeras noches en su habitación con una cama al lado en la
                    que dormirán papá o mamá
                  </strong>
                  . Estas primeras noches de adaptación a su habitación no{' '}
                  {lola}
                  dejaremos en toda la noche. {lola} dormiremos del mismo modo
                  que estamos haciendo, es decir, {formState.values.howSleep}.
                  Una vez se adapte a su espacio, empezaremos a trabajar el
                  sueño autónomo y ya mamá o papá podrán ir a dormir a su
                  habitación.
                </li>
                <li>
                  Podemos en este punto empezar a hacer el{' '}
                  <strong>ritual</strong> antes de ir a dormir e ir{' '}
                  <strong>
                    adaptando poco a poco los horarios de las siestas
                  </strong>{' '}
                  y de la hora de ir a dormir.
                </li>
              </ul>
            </li>
          </>
        )}
        {!formState.values.startNight.includes('de los brazos') && (
          <li>
            <span className="tener">PASO 1: PRIMERAS NOCHES</span>
            <ul className="plan-ul">
              <li>
                El primer paso del plan de sueño es la adaptación de{' '}
                {formState.values.name} a los nuevos horarios y rutinas. Así que
                empezaremos por aquí, poco a poco y sin prisa.{' '}
              </li>
              <li>
                Estas primeras noches, después del ritual antes de ir a dormir,
                cuando ya nos dirijamos a la habitación, haremos el mismo
                procedimiento comentado de cerrar persianas, dar las buenas
                noches, cerrar las luces… pero no le dejaremos aún {despiertoa}{' '}
                en su cuna. ¿Qué vamos a hacer?{' '}
                <ul className="plan-ul">
                  {formState.values.startNight.includes('del pecho') && (
                    <>
                      <li>
                        Si {lola} duerme mamá: Una vez dentro de la habitación,
                        le daremos chupitos de {pechoBiberon} a{' '}
                        {formState.values.name} para relajarlo un poquito más,
                        pero nuestro objetivo final en estos primeros días es
                        que {formState.values.name} se duerma en los brazos de
                        mamá, no en su {pechoBiberon}. Será el primer paso de
                        nuestro camino. Cada día debemos darle menos chupitos de{' '}
                        {pechoBiberon}, para conseguir que se duerma en los
                        brazos.
                      </li>
                      <li>
                        Si {lola} duerme papá: En este caso, papá {lola} dormirá
                        directamente en brazos. Mamá puede darle unos chupitos
                        de
                        {pechoBiberon} antes de que papá {lola} coja en brazos y{' '}
                        {lola} meza. Yo os aconsejaría que le demos todo el{' '}
                        {pechoBiberon} que quiera en la actividad en el ritual
                        antes de ir a dormir y una vez dentro de la habitación
                        que papá ya {lola} pueda dormir sin esos chupitos.
                      </li>
                    </>
                  )}
                  {formState.values.startNight.includes('del biberon') && (
                    <>
                      <li>
                        <strong>
                          TEXTO PENDIENTE ALBA TEXTO PENDIENTE ALBA TEXTO
                          PENDIENTE ALBA TEXTO PENDIENTE ALBA TEXTO PENDIENTE
                          ALBA TEXTO PENDIENTE ALBA TEXTO PENDIENTE ALBA TEXTO
                          PENDIENTE ALBA
                        </strong>
                      </li>
                    </>
                  )}
                </ul>
              </li>
              <li>
                El objetivo principal es que tanto papá como mamá la duerman del
                mismo modo y en estas primeras noches lo que queremos conseguir
                es que {formState.values.name} se duerma en los brazos tanto de
                mamá, como de papá.
              </li>
              <li>
                Una vez {formState.values.name} esté totalmente dormido en
                nuestros brazos, {lola} pondremos en la cuna. Si se queda
                dormido genial y si se despierta, primero intentaremos{' '}
                {formState.values.gender === 'm'
                  ? singularPlurar === ''
                    ? 'calmarlo'
                    : 'carmarloslos'
                  : singularPlurar === ''
                  ? 'calmarla'
                  : 'carmarlas'}{' '}
                dentro de la cuna sin sacarlo con carícias y ruido blanco hecho
                por nosotros (shhh o tararear una canción con la M). Si no es
                posible{' '}
                {formState.values.gender === 'm'
                  ? singularPlurar === ''
                    ? 'carmarlo'
                    : 'carmarlos'
                  : singularPlurar === ''
                  ? 'carmarla'
                  : 'carmarlas'}{' '}
                de esta manera, no pasa nada, {lola} cogeremos, {lola}{' '}
                dormiremos otra vez en nuestros brazos o con ayuda de los
                chupitos (si los estamos usando) y {lola} volveremos a poner en
                la cuna. Todo este proceso, si mamá no puede, lo puede hacer
                papá.{' '}
              </li>
              <li>
                Iremos viendo con el seguimiento cuantos días necesita{' '}
                {formState.values.name} para acostumbrarse a este nuevo
                procedimiento e iremos pautando.{' '}
              </li>
              <li>
                Importante: Si mamá/papá no consiguen que se duerma, nos podemos
                intercambiar, pero nunca pasarnoslo en los brazos, sino ponerlo
                en la cuna, salir y que entre el otro padre/madre.
              </li>
              {formState.values.startNight.includes('del pecho') && (
                <li>
                  <span style={redLetterStyle}>
                    OBJETIVO DE ESTAS PRIMERAS NOCHES: Que no se duerma en el
                    pecho, sino que se duerma en nuestros brazos. Y que empiece
                    a dormir en su espacio. Si tenemos una cuna colecho (como os
                    he comentado en el apartado anterior), la podemos utilizar
                    antes que la cuna normal.
                  </span>
                </li>
              )}
            </ul>
          </li>
        )}
        <li>
          <span className="tener">
            {formState.values.startNight.includes('de los brazos')
              ? 'PASO 1:'
              : 'PASO 2: SIGUIENTE PASO'}{' '}
          </span>
        </li>
        {formState.values.startNight.includes('de los brazos') && (
          <li>
            El Primer paso en el camino es relajarlo en nuestros brazos, pero
            que no se quede{' '}
            {formState.values.gender === 'm'
              ? singularPlurar === ''
                ? 'dormido'
                : 'dormidos'
              : singularPlurar === ''
              ? 'dormida'
              : 'dormidas'}{' '}
            , que se quede{' '}
            {formState.values.gender === 'm'
              ? singularPlurar === ''
                ? 'adormilado'
                : 'adormilados'
              : singularPlurar === ''
              ? 'adormilada'
              : 'adormiladas'}
            .
          </li>
        )}

        {!formState.values.startNight.includes('de los brazos') && (
          <li>
            Una vez tengamos este primer objetivo conseguido, seguiremos un poco
            más. Ahora que se duerme completamente en nuestros brazos, el
            siguiente paso en el camino es relajarlo en nuestros brazos, pero
            que no se quede{' '}
            {formState.values.gender === 'm'
              ? singularPlurar === ''
                ? 'dormido'
                : 'dormidos'
              : singularPlurar === ''
              ? 'dormida'
              : 'dormidas'}{' '}
            , que se quede{' '}
            {formState.values.gender === 'm'
              ? singularPlurar === ''
                ? 'adormilado'
                : 'adormilados'
              : singularPlurar === ''
              ? 'adormilada'
              : 'adormiladas'}
            .
          </li>
        )}
        <li>
          Una vez{' '}
          {formState.values.gender === 'm'
            ? singularPlurar === ''
              ? 'adormilado'
              : 'adormilados'
            : singularPlurar === ''
            ? 'adormilada'
            : 'adormiladas'}{' '}
          y relajado pondremos a {formState.values.name} en su cuna y lo
          ayudaremos a acabar de dormirse con carícias intermitentes en
          distintas partes del cuerpo (culo, pies, manos, espalda, cabeza…) y
          ruido blanco hecho por nosotros.{' '}
        </li>
        <li>
          Nos sentaremos en una silla al lado de su cuna para estar a su lado en
          todo momento del proceso siempre dándole aquello que necesita.{' '}
        </li>
        <li>
          ¿Cuándo la sacaremos de la cuna? Si dentro de la cuna se queda
          {formState.values.gender === 'm'
            ? singularPlurar === ''
              ? 'tranquilo'
              : 'tranquilos'
            : singularPlurar === ''
            ? 'tranquila'
            : 'tranquilas'}{' '}
          o con una queja que sabemos que sabrá gestionar con nuestra ayuda no
          {lele}
          sacaremos de la cuna. Solamente {lele} sacaremos de la cuna, cuando
          veamos que se puede enfadar de una manera que no sepa gestionar {ella}{' '}
          solito, en este caso, {lele} volveremos a adormilar en nuestros brazos
          y {lele} pondremos en la cuna siguiendo el mismo procedimiento.
        </li>
        <li>
          Estaremos en la silla al lado de la cuna, hasta que se quede
          totalmente{' '}
          {formState.values.gender === 'm'
            ? singularPlurar === ''
              ? 'dormido'
              : 'dormidos'
            : singularPlurar === ''
            ? 'dormida'
            : 'dormidas'}{' '}
          y entonces saldremos de la habitación.{' '}
        </li>
        <li>
          Podemos{' '}
          {formState.values.gender === 'm'
            ? singularPlurar === ''
              ? 'cogerlo'
              : 'cogerlos'
            : singularPlurar === ''
            ? 'cogerla'
            : 'cogerlas'}{' '}
          y dejarlo las veces que sean necesarias, el objetivo es que no se
          duerma en nuestros brazos, sino directamente en la cuna. ¿Porqué?
          Porque de ese modo no se duerme en un sitio distinto a la cuna y podrá
          de ese modo gestionar los despertares de una mejor manera, porque
          cuando se despierte, reconocerá su cuna y el hecho de que se ha
          dormido allí, no en el pecho de mamá o en los brazos de papá o mamá.{' '}
        </li>
        <li>
          Cada día y a través del seguimiento que realicemos iremos reduciendo
          ese coger y dejar. Es importante tener en cuenta que si podemos
          tenemos que intentar no sacarlo de la cuna, ofrecerle todo nuestro
          apoyo, pero sin sacarlo.
        </li>
        <li>
          El último paso de esta segunda etapa del plan de sueño es poner a{' '}
          {formState.values.name} {despiertoa} en la cuna, y realizar los mismos
          pasos. Después de darle las buenas noches, {lola} pondremos en la cuna
          {despiertoa} y con carícias y ruido blanco realizado por nosotros la
          ayudaremos a que se duerma.{' '}
        </li>
        <li>
          Si llora mucho y está muy enfadado {lola} podemos coger, como os
          comentaba, pero en este caso ya no lo adormilaremos, ni lo dormiremos
          totalmente en nuestros brazos, sino que será un coger, calmar y dejar
          {despiertoa} otra vez en la cuna, ayudándolo a dormir con caricias y
          ruido blanco hecho por nosotros.
        </li>
        <li>
          <strong style={redLetterStyle}>
            Recordad: Si la queja es suave o está tranquilo hablando o haciendo
            ruiditos por ejemplo, la acompañaremos, pero no la cogeremos.
          </strong>
        </li>
        <li>
          Igual que el coger - dejar, las carícias las iremos reduciendo a
          medida que pasen los días y a medida que {formState.values.name} no
          nos necesite. Lo último que retiraremos será el refuerzo verbal (ruido
          blanco hecho por nosotros o tararear una canción con la M).{' '}
        </li>
        <li>
          <span className="tener">PASO 3: SEGUIMOS AVANZANDO </span>
        </li>
        <li>
          Ya tenemos a {formState.values.name} {despiertoa} en la cuna y {lola}{' '}
          ayudamos con caricias y ruido blanco hecho por nosotros.
        </li>
        <li>
          Para seguir avanzando, pondremos la silla que estaba al lado de su
          cuna, en mitad de la habitación. En esta posición le ofreceremos
          refuerzo verbal.
        </li>
        <li>
          Si en algún momento nos necesita un poco más, nos acercaremos,
          {`le${singularPlurar}`} daremos unas caricias y volveremos a nuestra
          posición.
        </li>
        <li>
          <span className="tener">PASO 4: UN POQUITO MÁS </span>
        </li>
        <li>
          {formState.values.name} se está durmiendo prácticamente {soloa},
          solamente necesita un poco de nuestro refuerzo verbal para que sepamos
          que estamos allí.
        </li>
        <li>
          En este momento podemos mover la silla en la puerta de la habitación,
          realizando los mismos pasos que en el punto anterior.{' '}
        </li>
        <li>
          Poco a poco y como sucedió con las caricias iremos reduciendo el
          refuerzo verbal a medida que vayamos viendo que{' '}
          {formState.values.name} no lo necesita.
        </li>
        <li>
          <span className="tener">PASO 5: ÚLTIMOS PASOS </span>
        </li>
        <li>
          El último paso es dejar a {formState.values.name} en su cuna,
          {formState.values.gender === 'm'
            ? singularPlurar === ''
              ? 'despierto'
              : 'despiertos'
            : singularPlurar === ''
            ? 'despierta'
            : 'despiertas'}{' '}
          , después de darle las buenas noches e irnos de la habitación.
        </li>
        <li>
          Parece un paso muy complicado, pero con todo el trabajo hecho en los
          pasos anteriores no representa un cambio muy radical en el proceso. Si
          vemos que no es posible vamos a alargar los pasos anteriores.
        </li>
      </ul>
      <div style={planTitleStyle}>PLAN DE SUEÑO: DESPERTARES Y SIESTAS</div>
      <ul className="plan-ul">
        <li>
          <span className="tener">DESPERTARES NOCTURNOS</span>
          <ul className="plan-ul">
            <li>
              Vamos en primer lugar a distinguir esos despertares que puedan ser
              por hambre y esos que sean por hábito. ¿Cómo lo haremos? Por las
              noches vamos a pautar un conjunto de tomas. Por la edad que tiene
              Carla seguramente necesite aún una o dos tomas por la noche. Esos
              despertares siempre actuará mamá dándole el pecho y no pasa nada
              si se queda dormida, lo ideal sería que no se durmiese en el pecho
              y pudiéramos seguir los pasos anteriores, pero lo iremos viendo
              según el seguimiento. Las tomas serán cada 4 horas, si dejamos dos
              tomas o si dejamos una, será en mitad de la noche, lo comentaremos
              todo el la primera videollamada.
            </li>
            <li>
              Podemos darle una toma dormida antes de que vosotros os vayáis a
              dormir, para que así haga un tirón más largo de sueño. Igualmente
              lo valoraremos juntos en la llamada.
            </li>
            <li>
              Todos los otros despertares que no sean por hambre y no hayan
              pasado las horas para la toma de pecho, dormiremos a Carla
              siguiendo los pasos descritos anteriormente.{' '}
              <strong style={redLetterStyle}>
                Pero primero tendremos en cuenta SIEMPRE:
              </strong>
              <ul className="plan-ul">
                <li>
                  Cómo es ese despertar. Es decir, si es un despertar en el que
                  hay una queja leve o Carla está tranquila aunque despierta en
                  la cuna, le dejaremos espacio para que ella practique esta
                  nueva habilidad de dormirse sola. No actuaremos rápidamente si
                  está tranquila.
                </li>
                <li>
                  Si llora, acudiremos directamente y primero intentaremos
                  calmarla siempre dentro de la cuna con caricias y refuerzo
                  verbal y si no es posible la cogeremos y actuaremos según
                  estamos haciendo al inicio de la noche, para ser siempre lo
                  más consistentes posibles con nuestra forma de actuar. Igual
                  actuarán igual papá y mamá. Por ejemplo: si estamos poniéndola
                  adormilada en la cuna, la cogeremos, la adormilaremos y la
                  pondremos en la cuna y la ayudaremos con carícias y ruido
                  blanco hecho por nosotros. En cambio si estamos ya en mitad de
                  la habitación haciendo solamente ruido blanco, nos sentaremos
                  en la silla en mitad de la habitación haciendo shhh y si no se
                  calma si podemos ir, acariciarla un momento y volver a nuestra
                  posición.
                </li>
              </ul>
            </li>
            <li>
              Puede ser que le sea más fácil a Carla que sea papá quien acuda en
              los despertares que no sean de hambre, porque no tiene el pecho,
              pero yo siempre soy partidaria de que podáis trabajar los dos con
              todo, para que así Carla sepa que papá y mamá hacen lo mismo.{' '}
            </li>
            <li>
              Importante no pasar a Carla a vuestra cama en ningún despertar, ni
              a las 7:00 de la mañana, porque ella no sabe qué hora es y puede
              esperar cada día más pronto a ir a la cama con vosotros.{' '}
            </li>
            <li>
              Es importante cogerla cada día menos, para que ella aprenda a
              calmarse con vuestra ayuda en su espacio, en su cuna. Lo iremos
              viendo con el seguimiento.{' '}
            </li>
            <li>
              Si se despierta a las tres horas y media de su última toma y hemos
              pautado por ejemplo que le vamos a dar pecho a las cuatro horas,
              podemos hacer dos cosas:
              <ul className="plan-ul">
                <li>
                  Darle el pecho aunque aún no hayan pasado las cuatro horas,
                  pero dárselo de una o {dormirloa} totalmente utilizando el
                  método y luego darle el pecho dormida.
                </li>
                <li>
                  Nunca, nunca le daremos el pecho tras haber llorado durante un
                  período de tiempo mientras intentamos {dormirloa}. Así que no
                  vamos a intentar {dormirloa} utilizando el método y si no se
                  duerme y ya han pasado las cuatro horas pautadas, darle el
                  pecho, porque puede ser un refuerzo (regalo) para ella y que
                  aprenda a llorar para conseguir su regalo.{' '}
                </li>
              </ul>
            </li>
            <li>
              <span className="tener">LAS SIESTAS</span>
              <ul className="plan-ul">
                <li>
                  Las siestas las empezaremos a trabajar cuando la noche esté
                  avanzando, hasta el momento es importante que se duerma de la
                  forma que para ella sea mejor. Pensad que tenemos que
                  conseguir que Carla no llegue sobre cansada por la noche para
                  que así aprenda una nueva forma de dormirse.
                </li>
                <li>
                  En las siestas haremos un mini ritual antes de ir a dormir,
                  parecido al de la noche pero un poquito más corto, haremos las
                  siestas a oscuras y seguiremos los mismos pasos que por la
                  noche, lo iremos comentando en el seguimiento.{' '}
                </li>
                <li>
                  Primero mejora el inicio de la noche, después los despertares
                  y más tarde las siestas. Duermen el día y la noche con dos
                  partes del cerebro distintas, por lo que no afecta que por el
                  día la durmamos con el pecho y por la noche le enseñemos otra
                  forma.{' '}
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <div style={planTitleStyle}>ASPECTOS IMPORTANTES</div>
      <ul className="plan-ul">
        <li>
          Es importante{' '}
          <strong>seguir el plan de sueño y mis recomendaciones</strong>{' '}
          (pautas, técnicas, herramientas…) para que todo el proceso funcione.{' '}
        </li>
        <li>
          No es un camino fácil, puesto que Carla seguramente no va a aceptar
          esa nueva forma de dormirse, pero con{' '}
          <strong>consistencia, esfuerzo y trabajo</strong>
          todo se puede conseguir.{' '}
        </li>
        <li>
          Recordaros que el <strong>llanto del bebé</strong> es una forma de
          comunicarse, puesto que aún son pequeños para hablar, pero un llanto
          acompañado nunca será un llanto tóxico para ellos.{' '}
        </li>
        <li>
          <strong>Los cambios son difíciles</strong>, requieren de tiempo y
          paciencia. Sed fuertes y manteneros juntos en este proceso y seguro
          vais a ver resultados más rápido de lo que pensáis. La clave del éxito
          es la constancia y la repetición.{' '}
          <strong style={redLetterStyle}>Vamos a ello ;)</strong>
        </li>
      </ul>
      <div style={planTitleStyle}>SEGUIMIENTO</div>
      <ul className="plan-ul">
        <li>
          Cada día tendréis que enviar el <strong>registro de sueño</strong> que
          os he adjuntado con el Plan de Sueño. Lo tendréis que enviar{' '}
          <strong>antes de las 12 del mediodía</strong> para que así yo os pueda
          dar el feedback de la noche anterior y saber cómo actuar en la noche
          que sigue. Podéis enviarme los mails diarios que necesitéis, estaremos
          en contacto continuo.{' '}
        </li>
        <li>
          Es importante que cualquier{' '}
          <strong>
            preocupación, duda, miedo que tengáis me lo hagáis saber, para así
            poder resolverlo y hablarlo
          </strong>
          . La confianza es básica en todo este proceso. Estoy aquí para
          ayudaros en todo momento y apoyaros en este nuevo camino que vais a
          comenzar.{' '}
        </li>
        <li>
          Tenemos la videollamada inicial, y luego dos videollamadas más, una en
          mitad del proceso y la otra a final, las podemos tener cuando os vaya
          mejor. Igualmente lo iremos comentando para hacer que sean lo más
          óptimas posibles.{' '}
        </li>
      </ul>
    </>
  );
};

export default DocumentPlan;
