import React from 'react';
import { HtmlEditor, MenuBar } from '@aeaton/react-prosemirror';
import { options, menu } from '@aeaton/react-prosemirror-config-default';

const Course = (props) => {
  const { formState, handleHtmlEditor, hasError, handleChangeOther } = props;

  const showDescriptionEditor = () => {
    let value = '';
    if (
      formState.values.video_course &&
      formState.values.video_course.description
    ) {
      value = formState.values.video_course.description;
    }

    return (
      <HtmlEditor
        name="video_course.description"
        options={options}
        value={value}
        onChange={(v) => handleHtmlEditor(v, 'video_course.description')}
        render={({ editor, view }) => (
          <div id="1">
            <MenuBar menu={menu} view={view} />
            {editor}
          </div>
        )}
      />
    );
  };

  return (
    <>
      <div className="form-field">
        <label htmlFor="video_course.description">
          <b>Descripción</b>
        </label>
        <div className="html-textarea-wrapper">{showDescriptionEditor()}</div>
        {hasError('video_course.description') && (
          <span className="field-error">resumen es requerido</span>
        )}
      </div>
      <div className="form-field">
        <label htmlFor="video_course.duration">
          <b>Duración del Curso</b>
        </label>
        <input
          type="text"
          placeholder="Duración del Curso"
          name="video_course.duration"
          onChange={handleChangeOther}
          defaultValue={
            formState.values.video_course
              ? formState.values.video_course.duration || ''
              : ''
          }
        />
        {hasError('video_course.duration') && (
          <span className="field-error">Duración del curso es requerido</span>
        )}
      </div>
      <div className="form-field">
        <label htmlFor="video_course.video_url">
          <b>URL Video del Curso</b>
        </label>
        <input
          type="text"
          placeholder="URL Video"
          name="video_course.video_url"
          onChange={handleChangeOther}
          defaultValue={
            formState.values.video_course
              ? formState.values.video_course.video_url
              : ''
          }
        />
      </div>
      <div className="form-field">
        <label>
          <input
            type="checkbox"
            id="video_course.show_video"
            name="video_course.show_video"
            onChange={handleChangeOther}
            defaultChecked={
              formState.values.video_course
                ? formState.values.video_course.show_video
                : false
            }
          />
          Mostrar Video al Usuario
        </label>
        <br />
      </div>
    </>
  );
};

export default Course;
