import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import validate from 'validate.js';
import ServicesService from '../../Services/ServicesService';
import WithValidToken from '../../common/hoc/WithValidToken';
import NEW_SCHEMA from './NewSchema';
import './new.css';

const New = () => {
  const history = useHistory();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      price: 0,
      expire: 0,
      service_type: 'professional',
      active: false,
    },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, NEW_SCHEMA);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleCancel = (event) => {
    history.push('/services');
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : !event.target.files
            ? event.target.value
            : event.target.files[0],
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formState.isValid) {
      return;
    }

    const data = new FormData();
    data.append('name', formState.values.name);
    data.append('category', formState.values.category);
    data.append('service_type', formState.values.service_type);
    data.append('active', formState.values.active);
    data.append('summary', formState.values.summary);
    data.append('image', formState.values.image_cover);
    data.append('price', formState.values.price);
    data.append('expire_in', formState.values.expire);

    await ServicesService.addServices(data).then((response) => {
      history.push(`/services/edit/${response.id}`);
    });
  };

  return (
    <div className="services-container">
      <div className="title">
        <h1>Nuevo Servicios</h1>
      </div>
      <form className="new-form" onSubmit={handleSubmit}>
        <div className="form-field">
          <label htmlFor="email">
            <b>Nombre del Servicio</b>
          </label>
          <input
            type="text"
            placeholder="Nombre del Servicio"
            name="name"
            onChange={handleChange}
          />
          {hasError('name') && (
            <span className="field-error">Nombre es requerido</span>
          )}
        </div>
        <div className="form-field">
          <label htmlFor="email">
            <b>Caterogia</b>
          </label>
          <input
            type="text"
            placeholder="Caterogia del Servicio"
            name="category"
            onChange={handleChange}
          />
          {hasError('category') && (
            <span className="field-error">Caterogia es requerido</span>
          )}
        </div>
        <div className="form-field">
          <label htmlFor="service_type">
            <b>Tipo Servicio</b>
          </label>
          <select
            name="service_type"
            onChange={handleChange}
            value={formState.values.service_type}
          >
            <option value="professional">Servicio Profesional</option>
            <option value="streaming">Webminar</option>
            <option value="document">Documentos/libros</option>
            <option value="video">Video Curso</option>
          </select>
        </div>
        <div className="form-field">
          <label htmlFor="active">
            <b>Activo</b>
          </label>
          <br />
          <input
            type="checkbox"
            name="active"
            value={formState.values.active}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label htmlFor="summary">
            <b>Resumen</b>
          </label>
          <br />
          <textarea name="summary" onChange={handleChange}>
            {formState.values.summary || ''}
          </textarea>
          {hasError('summary') && (
            <span className="field-error">resumen es requerido</span>
          )}
        </div>
        <div className="form-field">
          <label htmlFor="image_cover">
            <b>Imagen Portada (355 x 200)</b>
          </label>
          <br />
          <input
            name="image_cover"
            type="file"
            defaultValue={formState.values.description}
            onChange={handleChange}
            required
          />
          {hasError('image_cover') && (
            <span className="field-error">
              La imagen de portada es requerido
            </span>
          )}
        </div>
        <div className="form-field">
          <label htmlFor="price">
            <b>
              Precio <sup>Cero representa que el servicio es gratis</sup>
            </b>
          </label>
          <input
            type="number"
            placeholder="Precio del Servicio"
            name="price"
            onChange={handleChange}
            required
            defaultValue={formState.values.price}
          />
          {hasError('price') && (
            <span className="field-error">precio debe ser un número</span>
          )}
        </div>
        <div className="form-field">
          <label htmlFor="expire">
            <b>
              Expira en <sup>Cero representa que nunca expira</sup>
            </b>
          </label>
          <input
            type="number"
            placeholder="Días a los que expira el servicio"
            name="expire"
            onChange={handleChange}
            required
            defaultValue={formState.values.expire}
          />
          {hasError('expire') && (
            <span className="field-error">Expira debe ser un número</span>
          )}
        </div>
        <div className="buttons-container">
          <div className="cancel-button">
            <button className="button-error" onClick={handleCancel}>
              Cancelar
            </button>
          </div>
          <div className="save-button">
            <button className="button-success" onClick={handleSubmit}>
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithValidToken(New);
