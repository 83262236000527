import React, { useState, useEffect } from 'react';
import isEqual from 'lodash.isequal';
import Option from './Option';
import './question.css';

const Question = (props) => {
  const { handleChangeQuestions, initialData, handleDeleteQuestion } = props;
  const [showOptions, setShowOptions] = useState(false);
  const [question, setQuestion] = useState({
    sectionId: initialData.sectionId,
    questionId: initialData.questionId,
    questionType: initialData.questionType,
    questionTitle: initialData.questionTitle,
    options: initialData.options,
  });

  useEffect(() => {
    if (isEqual(question, initialData) && initialData.options.length > 0) {
      setShowOptions(true);
    }
  }, [initialData]);

  useEffect(() => {
    if (!isEqual(question, initialData)) {
      handleChangeQuestions(question);
    }
  }, [question]);

  const handleChangeType = (event) => {
    const value = event.target.value;

    setQuestion({
      ...question,
      questionType: value,
    });

    if (['radio', 'checkbox'].includes(value)) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };

  const handleChange = (event) => {
    event.persist();
    const value = event.target.value;
    setQuestion({
      ...question,
      questionTitle: value,
    });
  };

  const handleDeleteOption = (event) => {
    const idToDelete = event.target.dataset.optionId;

    const updatedOptions = question.options.filter(
      (option) => option.optionId !== idToDelete
    );

    setQuestion({
      ...question,
      options: updatedOptions,
    });
  };

  const handleAddOption = (initialOptionData) => {
    if (initialOptionData && initialOptionData.optionTitle) {
    } else {
      const optionId = `optionId-${(+new Date()).toString()}`;

      const newOption = {
        questionId: initialData.questionId,
        optionId,
        optionTitle: '',
      };

      setQuestion({
        ...question,
        options: [...question.options, newOption],
      });
    }
  };

  const handleOptionChange = (newOption) => {
    const optionExist = question.options.find(
      (option) => option.optionId === newOption.optionId
    );

    if (optionExist !== undefined) {
      const currentOptions = question.options;
      currentOptions.forEach((option) => {
        if (option.optionId === newOption.optionId) {
          option.optionTitle = newOption.optionTitle;
        }
      });
      setQuestion({
        ...question,
        options: currentOptions,
      });
    } else {
    }
  };

  const renderOptions = () => {
    if (question.options.length === 0) {
      return;
    }

    return question.options.map((option) => (
      <Option
        key={option.optionId}
        initialData={option}
        handleOptionChange={handleOptionChange}
        handleDeleteOption={handleDeleteOption}
      />
    ));
  };

  return (
    <div>
      <button
        className="circle-button delete-question"
        onClick={handleDeleteQuestion}
        data-question-id={initialData.questionId}
        type="button"
      >
        x
      </button>
      <div className="form-field">
        <label htmlFor="question-type" className="question-title">
          Tipo de Pregunta
        </label>
        <select
          name="question-type"
          onChange={handleChangeType}
          defaultValue={question.questionType}
        >
          <option value="">Seleccione un tipo de pregunta</option>
          <option value="text">texto simple</option>
          <option value="textarea">multilinea</option>
          <option value="date">fecha</option>
          <option value="number">numerico</option>
          <option value="radio">seleccion unica</option>
          <option value="checkbox">seleccion multiple</option>
        </select>
      </div>
      <div className="form-field question-title">
        <label htmlFor={initialData.questionId}>Pregunta</label>
        <input
          type="text"
          placeholder="Pregunta "
          name={initialData.questionId}
          onBlur={handleChange}
          defaultValue={question.questionTitle}
        />
      </div>
      {showOptions && (
        <div className="form-field options">
          <label htmlFor="options" className="question-title">
            Opciones
          </label>
          {renderOptions()}
          <div className="form-field">
            <button
              type="button"
              onClick={handleAddOption}
              className="button button-fixed"
            >
              agregar opción
            </button>
          </div>
        </div>
      )}
      <hr />
    </div>
  );
};

export default Question;
