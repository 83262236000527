import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import validate from 'validate.js';
import Spinner from '../../common/components/Spinner';
import Notification from '../../common/components/Notification';
import UsersService from '../../Services/UsersService';
import EDIT_SCHEMA from './EditSchema';

const Edit = () => {
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    UsersService.getUserById(params.id).then((response) => {
      setFormState({
        ...formState,
        values: {
          ...response[0],
        },
      });
      setLoading(false);
    });
  }, [params]);

  useEffect(() => {
    const errors = validate(formState.values, EDIT_SCHEMA);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const hasError = (field) => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formState.isValid) {
      return;
    }

    const data = {
      id: formState.values._id,
      name: formState.values.name,
      email: formState.values.email,
    };

    await UsersService.editUserById(data)
      .then((response) => {
        setShowSuccess(response.updated);
      })
      .catch((error) => console.log(error));
  };
  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : !event.target.files
            ? event.target.value
            : event.target.files[0],
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const handleCancel = () => {};
  return (
    <div className="services-container">
      <div className="title">
        <h1>Editar Usuario</h1>
      </div>
      {loading && <Spinner />}
      <form className="new-form" onSubmit={handleSubmit}>
        <div className="form-field">
          <label htmlFor="name">
            <b>Nombre del Servicio</b>
          </label>
          <input
            type="text"
            placeholder="Nombre del Usuario"
            name="name"
            onChange={handleChange}
            defaultValue={formState.values.name}
          />
          {hasError('name') && (
            <span className="field-error">Nombre es requerido</span>
          )}
        </div>
        <div className="form-field">
          <label htmlFor="category">
            <b>Email del usuario</b>
          </label>
          <input
            type="text"
            placeholder="Email del usuario"
            name="email"
            onChange={handleChange}
            defaultValue={formState.values.email}
          />
          {hasError('email') && (
            <span className="field-error">Email es requerido</span>
          )}
        </div>

        <div className="buttons-container">
          {loading && <Spinner />}
          {!loading && (
            <>
              <div className="cancel-button">
                <button className="button-error" onClick={handleCancel}>
                  Cancelar
                </button>
              </div>
              <div className="save-button">
                <button className="button-success" onClick={handleSubmit}>
                  Guardar
                </button>
              </div>
            </>
          )}
        </div>
        {showSuccess && (
          <Notification severity="success" text="Usuario Actualizado 🎉" />
        )}
      </form>
    </div>
  );
};

export default Edit;
