import React, { useState, useEffect } from 'react';
import isEqual from 'lodash.isequal';
import Section from './Section';
import './section.css';

const QuestionaryGenerator = (props) => {
  const { handleQuestionary, initialData } = props;
  const [questionaryState, setQuestionaryState] = useState({
    questionaryInfo: {
      questionaryTitle: '',
      questionaryIntro: '',
    },
    sections: [],
  });

  useEffect(() => {
    if (initialData) {
      setQuestionaryState(initialData);
    }
  }, [initialData]);

  const handleChange = (event) => {
    setQuestionaryState({
      ...questionaryState,
      questionaryInfo: {
        ...questionaryState.questionaryInfo,
        [event.target.name]: event.target.value,
      },
    });
  };

  useEffect(() => {
    if (!isEqual(questionaryState, initialData)) {
      handleQuestionary(questionaryState);
    }
  }, [questionaryState]);

  const handleChangeSection = (newSection) => {
    const sectionExist = questionaryState.sections.find(
      (section) => section.sectionId === newSection.sectionId
    );
    if (sectionExist !== undefined) {
      const currentSections = questionaryState.sections;
      currentSections.forEach((section) => {
        if (section.sectionId === newSection.sectionId) {
          section.sectionTitle = newSection.sectionTitle;
          section.questions = newSection.questions;
        }
      });

      setQuestionaryState((questionaryState) => ({
        ...questionaryState,
        sections: currentSections,
      }));
    } else {
      setQuestionaryState((questionaryState) => ({
        ...questionaryState,
        sections: [...questionaryState.sections, newSection],
      }));
    }
  };

  const handleDeleteSection = (event) => {
    const idToDelete = event.target.dataset.sectionId;
    const updatedSections = questionaryState.sections.filter(
      (section) => section.sectionId !== idToDelete
    );

    setQuestionaryState({
      ...questionaryState,
      sections: updatedSections,
    });
  };

  const handleAddSection = (initialSectionData) => {
    if (initialSectionData.sectionTitle || initialSectionData.questions) {
    } else {
      const sectionId = `section-${(+new Date()).toString()}`;
      const newSection = {
        sectionId,
        sectionTitle: '',
        questions: [],
      };

      setQuestionaryState((questionaryState) => ({
        ...questionaryState,
        sections: [...questionaryState.sections, newSection],
      }));
    }
  };

  const renderSections = () => {
    return questionaryState.sections.map((section) => (
      <Section
        key={section.sectionId}
        handleChangeSection={handleChangeSection}
        handleDeleteSection={handleDeleteSection}
        initialData={section}
      />
    ));
  };

  return (
    <div className="questionary-container">
      <div className="form-field">
        <label htmlFor="questionaryTitle">
          <b>Titulo del Cuestionario</b>
        </label>
        <input
          type="text"
          placeholder="Titulo del Cuestionario"
          name="questionaryTitle"
          onBlur={handleChange}
          defaultValue={questionaryState.questionaryInfo.questionaryTitle}
        />
      </div>
      <div className="form-field">
        <label htmlFor="questionaryIntro">
          <b>Introduccion del Cuestionario</b>
        </label>
        <textarea
          name="questionaryIntro"
          onBlur={handleChange}
          defaultValue={questionaryState.questionaryInfo.questionaryIntro}
        />
      </div>
      {renderSections()}
      <div className="form-field">
        <button
          type="button"
          onClick={handleAddSection}
          className="button button-fixed"
        >
          agregar sección de pregunta
        </button>
      </div>
    </div>
  );
};

export default QuestionaryGenerator;
