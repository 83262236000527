import fetchCall from './ComunicationService';

const CouponService = () => {
  const getCoupons = () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http('discounts/coupons', {}, headers, 'GET', {})
      .then((response) => response.discounts)
      .catch((error) => console.log(error));
  };

  const addCoupon = (data) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    console.log(data);

    return fetchCall
      .http('discounts/coupons', {}, headers, 'POST', data, false)
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  const getCoupon = (id) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`discounts/coupons/${id}`, {}, headers, 'GET', {})
      .then((response) => response[0])
      .catch((error) => console.log(error));
  };

  const editCoupon = (id, data) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`discounts/coupons/${id}`, {}, headers, 'PUT', data, false)
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  const deleteCoupon = (id) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`discounts/coupons/${id}`, {}, headers, 'DELETE', {})
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  return {
    getCoupons,
    addCoupon,
    getCoupon,
    editCoupon,
    deleteCoupon,
  };
};

export default CouponService();
