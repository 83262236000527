import fetchCall from './ComunicationService';

const ScheduleService = () => {
  const getSchedules = () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http('schedules/all', {}, headers, 'GET', {})
      .then((response) => response.schedules)
      .catch((error) => console.log(error));
  };

  const addSchedule = (data) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http('schedules/schedule', {}, headers, 'POST', data, false)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  };

  const deleteSchedule = (id) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`schedules/schedule/${id}`, {}, headers, 'DELETE', {})
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  return {
    addSchedule,
    getSchedules,
    deleteSchedule,
  };
};

export default ScheduleService();
