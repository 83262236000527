const NEW_SCHEMA = {
  coupon: {
    presence: { allowEmpty: false },
  },
  amount: {
    presence: { allowEmpty: false },
  },
  is_precent: {
    presence: { allowEmpty: false },
  },
  quantity: {
    presence: { allowEmpty: false },
  },
  start_date: {
    presence: { allowEmpty: false },
  },
  end_date: {
    presence: { allowEmpty: false },
  },
  services: {
    presence: { allowEmpty: false },
  },
};

export default NEW_SCHEMA;
