import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import './Logout.css';

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem('token');
    history.push('/');
  }, [history]);

  return (
    <div className="wrapper">
      <div className="box">
        <div className="container">
          <div className="logo-container">
            <img src={logo} alt="mimate mama" className="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
