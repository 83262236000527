const EDIT_SCHEMA = {
  name: {
    presence: { allowEmpty: false },
  },
  email: {
    presence: { allowEmpty: false },
  },
};

export default EDIT_SCHEMA;
