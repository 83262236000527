import React, { useState, useEffect } from 'react';
import WithValidToken from '../../common/hoc/WithValidToken';
import sleepTable from './sleepTable';
import scheduleTable from './scheduleTable';
import DocumentPlan from './DocumentPlan';
import './generator.css';

const defaultState = {
  values: {
    totalChildren: 1,
    gender: 'm',
    name: 'Jean',
    age: '8,5',
    sleepwhere: 'en la cama con papá',
    totalNap: 1,
    totalNapText: 'una',
    napDescriptions: ['la primera sobre las '],
    bedtime: '20:00',
    ritualtime: '19:00',
    ritualDescriptions: [],
    ayudas: [],
    startNight: [],
    middleNight: [],
    objective: [],
  },
};

const oldFormState =
  JSON.parse(localStorage.getItem('generador')) || defaultState;

const Generator = (props) => {
  const [formState, setFormState] = useState(oldFormState);
  const [singularPlurar, setSingularPlurar] = useState('');
  const [tablaSueno, setTablaSueno] = useState({});
  const [tablaHorario, setTablaHorario] = useState({});
  const [lola, setLola] = useState('lo');
  const [lele, setLele] = useState('le');
  const [despiertoa, setDespiertoa] = useState('despierto');
  const [soloa, setSoloa] = useState('solo');
  const [ella, setElla] = useState('él');
  const [cansadoa, setCansadoa] = useState('cansado');
  const [dormirloa, setDormirloa] = useState('dormirlo');
  const [pechoBiberon, setPechoBiberon] = useState('pecho');

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        ayudas: [],
        startNight: [],
        middleNight: [],
        ritualDescriptions: [],
        objective: [],
      },
    }));
  }, []);

  useEffect(() => {
    if (formState.values.totalChildren === '2') {
      setSingularPlurar('s');
    } else {
      setSingularPlurar('');
    }
    const currentAge = parseInt(formState.values.age) || 0;
    setTablaSueno(sleepTable[currentAge]);
    setTablaHorario(
      scheduleTable[currentAge] ? scheduleTable[currentAge] : scheduleTable[6]
    );

    if (formState.values.gender === 'm') {
      if (singularPlurar === '') {
        setLola('lo');
        setDespiertoa('despierto');
        setLele('le');
        setSoloa('solo');
        setElla('él');
        setCansadoa('cansado');
        setDormirloa('dormirlo');
      } else {
        setLola('los');
        setDespiertoa('despiertos');
        setLele('les');
        setSoloa('solos');
        setElla('ellos');
        setCansadoa('cansados');
        setDormirloa('dormirlos');
      }
    } else {
      if (singularPlurar === '') {
        setLola('la');
        setLele('la');
        setDespiertoa('despierta');
        setSoloa('sola');
        setElla('ella');
        setCansadoa('cansada');
        setDormirloa('dormirla');
      } else {
        setLola('las');
        setDespiertoa('despiertas');
        setLele('las');
        setSoloa('solas');
        setElla('ellas');
        setCansadoa('cansadas');
        setDormirloa('dormirlas');
      }
    }

    if (formState.values.startNight.includes('del biberon')) {
      setPechoBiberon('biberon');
    }

    if (formState.values.startNight.includes('del pecho')) {
      setPechoBiberon('pecho');
    }

    localStorage.setItem('generador', JSON.stringify(formState));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : !event.target.files
            ? event.target.value
            : event.target.files[0],
      },
    }));
  };

  const handleChangeNapDescription = (event) => {
    event.persist();

    let newDescriptions = formState.values.napDescriptions.map(
      (description, index) => {
        return index == event.target.dataset.index
          ? event.target.value
          : description;
      }
    );

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        napDescriptions: [...newDescriptions],
      },
    }));
  };

  const handleChangeRitualDescription = (event) => {
    event.persist();
    console.log(event.target.value);
    let newDescriptions = formState.values.ritualDescriptions.map(
      (description, index) => {
        return index == event.target.dataset.index
          ? event.target.value
          : description;
      }
    );

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        ritualDescriptions: [...newDescriptions],
      },
    }));
  };

  const renderNapDescription = () => {
    return formState.values.napDescriptions.map((nap, index) => (
      <input
        key={index}
        type="text"
        name={`nap${index}`}
        data-index={index}
        defaultValue={formState.values.napDescriptions[index]}
        onChange={handleChangeNapDescription}
      />
    ));
  };

  const renderRitualDescription = () => {
    return formState.values.ritualDescriptions.map((nap, index) => (
      <input
        key={index}
        type="text"
        name={`ritual${index}`}
        data-index={index}
        defaultValue={formState.values.ritualDescriptions[index]}
        onChange={handleChangeRitualDescription}
      />
    ));
  };

  const appendNapDescription = (event) => {
    event.preventDefault();
    if (formState.values.napDescriptions.length === 3) {
      return;
    }
    const total = formState.values.napDescriptions.length + 1;
    let totalText = 'una';
    let description = 'la primera';
    switch (total) {
      case 2:
        totalText = 'dos';
        description = 'la segunda sobre las ';
        break;
      case 3:
        totalText = 'tres';
        description = 'la tercera sobre las ';
        break;
      default:
        break;
    }
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        totalNap: total,
        totalNapText: totalText,
        napDescriptions: [...formState.values.napDescriptions, description],
      },
    }));
  };

  const appendRitualDescription = (event) => {
    event.preventDefault();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        ritualDescriptions: [...formState.values.ritualDescriptions, ''],
      },
    }));
  };

  const renderSiestas = () => {
    return formState.values.napDescriptions.length === 3
      ? `${formState.values.napDescriptions[0]}, ${formState.values.napDescriptions[1]} y ${formState.values.napDescriptions[2]}`
      : formState.values.napDescriptions.join(' y ');
  };

  const renderRituales = () => {
    return formState.values.ritualDescriptions.join(', ');
  };

  const handleHorarios = (event) => {
    event.persist();
    if (event.target.checked) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          ayudas: [...formState.values.ayudas, event.target.value],
        },
      }));
    } else {
      const filterHelpers = formState.values.ayudas.filter(
        (ayuda) => ayuda !== event.target.value
      );
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          ayudas: [...filterHelpers],
        },
      }));
    }
  };

  const handleStarNight = (event) => {
    event.persist();
    if (event.target.checked) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          startNight: [...formState.values.startNight, event.target.value],
        },
      }));
    } else {
      const filterHelpers = formState.values.startNight.filter(
        (ayuda) => ayuda !== event.target.value
      );
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          startNight: [...filterHelpers],
        },
      }));
    }
  };

  const handleMiddleNight = (event) => {
    event.persist();
    if (event.target.checked) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          middleNight: [...formState.values.middleNight, event.target.value],
        },
      }));
    } else {
      const filterHelpers = formState.values.middleNight.filter(
        (ayuda) => ayuda !== event.target.value
      );
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          middleNight: [...filterHelpers],
        },
      }));
    }
  };

  const handleObjective = (event) => {
    event.persist();
    if (event.target.checked) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          objective: [...formState.values.objective, event.target.value],
        },
      }));
    } else {
      const filterHelpers = formState.values.objective.filter(
        (ayuda) => ayuda !== event.target.value
      );
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          objective: [...filterHelpers],
        },
      }));
    }
  };

  const renderStartNight = () => {
    if (formState.values.startNight.length === 0) {
      return;
    }
    return `con ayuda ${
      formState.values.startNight.length > 1
        ? formState.values.startNight.join(' y ')
        : formState.values.startNight.join(', ')
    }`;
  };

  const renderMiddleNight = () => {
    if (formState.values.middleNight.length === 0) {
      return;
    }
    return `y en los despertares ${
      formState.values.middleNight.length > 1
        ? formState.values.middleNight.join(' y ')
        : formState.values.middleNight.join(', ')
    }`;
  };

  const renderHelpersHorario = () => {
    if (formState.values.ayudas.length === 0) {
      return;
    }
    return `sin la ayuda ${
      formState.values.ayudas.length > 1
        ? formState.values.ayudas.join(' y ')
        : formState.values.ayudas.join(', ')
    }`;
  };

  const tablaSuenoStyle = {
    marginLeft: '0px',
    marginRight: '0px',
    width: '600px',
    border: '1px solid #000000',
    borderCollapse: 'collapse',
  };

  const tablaColumnTitleStyle = {
    height: '33px',
    display: 'table-cell',
    width: '231px',
    backgroundColor: 'rgb(249, 203, 156)',
    borderWidth: '1px',
    borderColor: 'rgb(0, 0, 0)',
    borderStyle: 'solid',
    textAlign: 'center',
  };

  const tablaColumnValueStyle = {
    height: '33px',
    display: 'table-cell',
    borderWidth: '1px',
    borderColor: 'rgb(0, 0, 0)',
    borderStyle: 'solid',
    textAlign: 'center',
  };

  const renderTableSueno = () => {
    return (
      <table style={tablaSuenoStyle}>
        <tbody>
          <tr>
            <td style={tablaColumnTitleStyle}>SUEÑO TOTAL</td>
            <td style={tablaColumnValueStyle}>{tablaSueno.sTotal}</td>
          </tr>
          <tr>
            <td style={tablaColumnTitleStyle}>SUEÑO DIURNO</td>
            <td style={tablaColumnValueStyle}>{tablaSueno.sDiurno}</td>
          </tr>
          <tr>
            <td style={tablaColumnTitleStyle}>SIESTAS</td>
            <td style={tablaColumnValueStyle}>{tablaSueno.siestas}</td>
          </tr>
          <tr>
            <td style={tablaColumnTitleStyle}>SUEÑO NOCTURNO</td>
            <td style={tablaColumnValueStyle}>{tablaSueno.sNocturno}</td>
          </tr>
          <tr>
            <td style={tablaColumnTitleStyle}>VENTANA DE SUEÑO</td>
            <td style={tablaColumnValueStyle}>{tablaSueno.ventanas}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderHorariosSueno = () => {
    return (
      <table style={tablaSuenoStyle}>
        <tbody>
          <tr>
            <td style={tablaColumnTitleStyle}>BUENOS DÍAS</td>
            <td style={tablaColumnValueStyle}>{tablaHorario.buenosDias}</td>
          </tr>
          <tr>
            <td style={tablaColumnTitleStyle}>PRIMERA SIESTA</td>
            <td style={tablaColumnValueStyle}>{tablaHorario.primeraSiesta}</td>
          </tr>
          {tablaHorario.segundaSiesta && (
            <tr>
              <td style={tablaColumnTitleStyle}>SEGUNDA SIESTA</td>
              <td style={tablaColumnValueStyle}>
                {tablaHorario.segundaSiesta}
              </td>
            </tr>
          )}
          {tablaHorario.terceraSiesta && (
            <tr>
              <td style={tablaColumnTitleStyle}>TERCERA SIESTA</td>
              <td style={tablaColumnValueStyle}>
                {tablaHorario.terceraSiesta}
              </td>
            </tr>
          )}
          <tr>
            <td style={tablaColumnTitleStyle}>
              {formState.values.name.toUpperCase()} DORMIDO
            </td>
            <td style={tablaColumnValueStyle}>{tablaHorario.dormido}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div className="generator-container">
      <div className="title">
        <h3>Generador de Planes de Sueños</h3>
      </div>
      <hr />
      <form>
        <div className="form-field">
          <label htmlFor="totalChildren">
            <b>Número de niños</b>
          </label>
          <select
            id="totalChildren"
            name="totalChildren"
            defaultValue={formState.values.totalChildren}
            onChange={handleChange}
          >
            <option value="1">1</option>
            <option value="2">2</option>
          </select>
        </div>
        <div className="form-field">
          <label htmlFor="gender">
            <b>Sexo</b>
          </label>
          <select
            id="gender"
            name="gender"
            defaultValue={formState.values.gender}
            onChange={handleChange}
          >
            <option value="m">Masculino</option>
            <option value="f">Femenino</option>
          </select>
        </div>
        <hr />
        <h4 className="plan-title">Información Inicial</h4>
        <div className="form-field">
          <label htmlFor="name">
            <b>Nombre del niño/a</b>
          </label>
          <input
            type="text"
            placeholder="Nombre del niño/a"
            id="name"
            name="name"
            defaultValue={formState.values.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label htmlFor="age">
            <b>Edad del niño/a</b>
          </label>
          <input
            type="text"
            placeholder="Edad"
            id="age"
            name="age"
            defaultValue={formState.values.age}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label htmlFor="sleepwhere">
            <b>Dónde Duerme</b>
          </label>
          <select
            id="sleepwhere"
            name="sleepwhere"
            onChange={handleChange}
            defaultValue={formState.values.sleepwhere}
          >
            <option value="en la cama con papá">en la cama con papá</option>
            <option value="en la cama con mamá">en la cama con mamá</option>
            <option value="en la cama con sus padres">
              en la cama con sus padres
            </option>
            <option value="en su cuna en la habitación de los padres">
              en su cuna en la habitación de los padres
            </option>
            <option value="en la cuna en su habitación">
              en la cuna en su habitación
            </option>
          </select>
        </div>
        <div className="form-field">
          <label>
            <b>Cómo Duerme al inicio de la noche</b>
          </label>
        </div>
        <div className="form-field">
          <input
            type="checkbox"
            id="snNoArms"
            name="snNoArms"
            value="de los brazos"
            onChange={handleStarNight}
          ></input>
          <label htmlFor="snNoArms">
            <b>Brazos</b>
          </label>
        </div>
        <div className="form-field">
          <input
            type="checkbox"
            id="snNoBreastfeeding"
            name="snNoBreastfeeding"
            value="del pecho"
            onChange={handleStarNight}
          ></input>
          <label htmlFor="snNoBreastfeeding">
            <b>Pecho</b>
          </label>
        </div>
        <div className="form-field">
          <input
            type="checkbox"
            id="snNoBottle"
            name="snNoBottle"
            value="del biberon"
            onChange={handleStarNight}
          ></input>
          <label htmlFor="snNoBottle">
            <b>Biberon</b>
          </label>
        </div>
        <div className="form-field">
          <label>
            <b>Cómo Duerme durante los despertares</b>
          </label>
        </div>
        <div className="form-field">
          <input
            type="checkbox"
            id="mnNoArms"
            name="mnNoArms"
            value="con ayuda de los brazos"
            onChange={handleMiddleNight}
          ></input>
          <label htmlFor="mnNoArms">
            <b>Brazos</b>
          </label>
        </div>
        <div className="form-field">
          <input
            type="checkbox"
            id="mnNoBreastfeeding"
            name="mnNoBreastfeeding"
            value="con ayuda del pecho"
            onChange={handleMiddleNight}
          ></input>
          <label htmlFor="mnNoBreastfeeding">
            <b>Pecho</b>
          </label>
        </div>
        <div className="form-field">
          <input
            type="checkbox"
            id="mnNoBottle"
            name="mnNoBottle"
            value="con ayuda del biberon"
            onChange={handleMiddleNight}
          ></input>
          <label htmlFor="mnNoBottle">
            <b>Biberon</b>
          </label>
        </div>
        <div className="form-field">
          <input
            type="checkbox"
            id="mnNoParentsBed"
            name="mnNoParentsBed"
            value="pasa a la cama de los padres"
            onChange={handleMiddleNight}
          ></input>
          <label htmlFor="mnNoParentsBed">
            <b>Pasa a la cama de los padres</b>
          </label>
        </div>
        <div className="form-field">
          <label htmlFor="totalNap">
            <b>Siestas</b>
          </label>
          <br />
          <button onClick={appendNapDescription}>Agregar otra siesta</button>
          {renderNapDescription()}
        </div>
        <div className="form-field">
          <label htmlFor="bedtime">
            <b>Hora de dormir</b>
          </label>
          <input
            type="time"
            placeholder="Hora de dormir"
            id="bedtime"
            name="bedtime"
            defaultValue={formState.values.bedtime}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label htmlFor="ritualtime">
            <b>Hora Inicio Ritual</b>
          </label>
          <input
            type="time"
            placeholder="Inicio Ritual"
            id="ritualtime"
            name="ritualtime"
            defaultValue={formState.values.ritualtime}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label htmlFor="totalNap">
            <b>Rituales</b>
          </label>
          <br />
          <button onClick={appendRitualDescription}>Agregar otro ritual</button>
          {renderRitualDescription()}
        </div>
        <hr />
        <h4 className="plan-title">Objectivos a Conseguir</h4>
        <div className="form-field">
          <input
            type="checkbox"
            id="objectiveBedroom"
            name="objectiveBedroom"
            value="habitación"
            onChange={handleObjective}
          ></input>
          <label htmlFor="objectiveBedroom">
            <b>Que se capaz de dormirse en su habitacón</b>
          </label>
        </div>
        <hr />
        <h4 className="plan-title">
          Como duerme al inicio, aplica para "primer contacto con la cuna y
          adaptación a su habitación
        </h4>
        <textarea
          id="howSleep"
          name="howSleep"
          defaultValue={formState.values.howSleep}
          onChange={handleChange}
        ></textarea>
        <hr />
        <h4 className="plan-title">Horario</h4>
        <div className="form-field">
          <input
            type="checkbox"
            id="noArms"
            name="noArms"
            value="de los brazos"
            onChange={handleHorarios}
          ></input>
          <label htmlFor="noArms">
            <b>sin la ayuda de los brazos</b>
          </label>
        </div>
        <div className="form-field">
          <input
            type="checkbox"
            id="noBreastfeeding"
            name="noBreastfeeding"
            value="del pecho"
            onChange={handleHorarios}
          ></input>
          <label htmlFor="noBreastfeeding">
            <b>sin la ayuda del pecho</b>
          </label>
        </div>
        <div className="form-field">
          <input
            type="checkbox"
            id="noBottle "
            name="noBottle"
            value="del biberon"
            onChange={handleHorarios}
          ></input>
          <label htmlFor="noBottle">
            <b>sin la ayuda del biberon</b>
          </label>
        </div>
        <hr />
      </form>
      <hr />
      <DocumentPlan
        formState={formState}
        singularPlurar={singularPlurar}
        tablaSueno={tablaSueno}
        tablaHorario={tablaHorario}
        lola={lola}
        lele={lele}
        despiertoa={despiertoa}
        soloa={soloa}
        ella={ella}
        cansadoa={cansadoa}
        renderSiestas={renderSiestas}
        renderRituales={renderRituales}
        renderStartNight={renderStartNight}
        renderMiddleNight={renderMiddleNight}
        renderHelpersHorario={renderHelpersHorario}
        renderTableSueno={renderTableSueno}
        renderHorariosSueno={renderHorariosSueno}
        dormirloa={dormirloa}
        pechoBiberon={pechoBiberon}
      />
    </div>
  );
};

export default WithValidToken(Generator);
