import React, { useState, useEffect } from 'react';
import Question from './Question';
import './section.css';

const Section = (props) => {
  const { handleChangeSection, handleDeleteSection, initialData } = props;
  const [section, setSection] = useState({
    sectionId: initialData.sectionId,
    sectionTitle: initialData.sectionTitle,
    questions: initialData.questions,
  });

  useEffect(() => {
    if (section.sectionTitle !== '') {
      handleChangeSection(section);
    }
  }, [section]);

  const handleChange = (event) => {
    setSection({
      ...section,
      sectionId: initialData.sectionId,
      sectionTitle: event.target.value,
    });
  };

  const handleAddQuestion = (initialQuestionData) => {
    if (initialQuestionData.questionType && initialQuestionData.questionTitle) {
    } else {
      const questionId = `question-${(+new Date()).toString()}`;

      const newQuestion = {
        sectionId: initialData.sectionId,
        questionId,
        questionType: 'text',
        questionTitle: '',
        options: [],
      };

      setSection({
        ...section,
        questions: [...section.questions, newQuestion],
      });
    }
  };

  const handleChangeQuestions = (newQuestion) => {
    const questionExist = section.questions.find(
      (question) => question.questionId === newQuestion.questionId
    );

    if (questionExist !== undefined) {
      const currentQuestions = section.questions;
      currentQuestions.forEach((question) => {
        if (question.questionId === newQuestion.questionId) {
          question.questionType = newQuestion.questionType;
          question.questionTitle = newQuestion.questionTitle;
          question.options = newQuestion.options;
        }
      });
      setSection({
        ...section,
        questions: currentQuestions,
      });
    } else {
    }
  };

  const handleDeleteQuestion = (event) => {
    const idToDelete = event.target.dataset.questionId;

    const updatedQuestions = section.questions.filter(
      (question) => question.questionId !== idToDelete
    );

    setSection({
      ...section,
      questions: updatedQuestions,
    });
  };

  const renderQuestions = () => {
    return section.questions.map((question) => (
      <Question
        key={question.questionId}
        initialData={question}
        handleChangeQuestions={handleChangeQuestions}
        handleDeleteQuestion={handleDeleteQuestion}
      />
    ));
  };

  return (
    <div className="section">
      <button
        className="circle-button delete-section"
        onClick={handleDeleteSection}
        data-section-id={initialData.sectionId}
        type="button"
      >
        x
      </button>
      <div className="form-field">
        <label htmlFor={initialData.sectionId} className="section-title">
          Nombre de la sección
        </label>
        <input
          type="text"
          placeholder="Nombre de la sección"
          name={initialData.sectionId}
          onBlur={handleChange}
          defaultValue={section.sectionTitle}
        />
      </div>
      {renderQuestions()}
      <div className="form-field">
        <button
          type="button"
          onClick={handleAddQuestion}
          className="button button-fixed"
        >
          agregar pregunta
        </button>
      </div>
    </div>
  );
};

export default Section;
