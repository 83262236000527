import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import WithValidToken from '../../common/hoc/WithValidToken';
import ContractService from '../../Services/ContractsService';
import './contract.css';

function Contract(props) {
  const history = useHistory();
  const params = useParams();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      completed: false,
      questionary: {},
    },
    touched: {},
    errors: {},
    questions: {},
  });

  useEffect(() => {
    // buscar questionario en el back
    ContractService.getUserContract(params.id).then((response) => {
      console.log(response);
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          questionary: response.questionary,
        },
        questions: {
          ...formState.questions,
          ...response.service_id.initial_questions,
        },
      }));
    });
  }, []);

  const renderTitle = () => {
    if (!formState.questions.questionaryInfo) {
      return;
    }

    return (
      <>
        <div className="questionryTitle">
          <h1>
            {formState.questions.questionaryInfo.questionaryTitle} -{' '}
            {history.location.state.clientName}
          </h1>
        </div>
      </>
    );
  };

  const renderQuestionField = (question) => {
    let field = null;

    switch (question.questionType) {
      case 'number':
        field = (
          <input
            type="number"
            name={question.questionId}
            defaultValue={
              formState.values.questionary &&
              formState.values.questionary[question.questionId]
                ? formState.values.questionary[question.questionId]
                : ''
            }
          />
        );
        break;
      case 'textarea':
      case 'text':
        field = (
          <textarea
            name={question.questionId}
            defaultValue={
              formState.values.questionary &&
              formState.values.questionary[question.questionId]
                ? formState.values.questionary[question.questionId]
                : ''
            }
          />
        );
        break;
      case 'radio':
        field = question.options.map((option, key) => (
          <div key={key}>
            <input
              type="radio"
              id={option.optionId}
              name={question.questionId}
              value={option.optionTitle}
              checked={
                formState.values.questionary &&
                formState.values.questionary[question.questionId] ===
                  option.optionTitle
                  ? true
                  : false
              }
            />
            <label htmlFor={option.optionId}>{option.optionTitle}</label>
            <br></br>
          </div>
        ));
        break;
      case 'date':
        field = (
          <input
            name={question.questionId}
            type="date"
            defaultValue={
              formState.values.questionary &&
              formState.values.questionary[question.questionId]
                ? formState.values.questionary[question.questionId]
                : ''
            }
          />
        );
        break;
      case 'checkbox':
        field = question.options.map((option, key) => (
          <>
            <input
              key={key}
              type="checkbox"
              name={option.optionId}
              checked={
                formState.values.questionary &&
                formState.values.questionary[option.optionId]
                  ? true
                  : false
              }
            />
            {option.optionTitle}
            <br />
          </>
        ));
        break;
      default:
        break;
    }

    return field;
  };

  const renderSectionQuestions = (questions) => {
    return questions.map((question, index) => {
      return (
        <h4 className="questionText" key={index}>
          {question.questionTitle}
          <br />
          <br /> {renderQuestionField(question)}
        </h4>
      );
    });
  };

  const renderQuestions = () => {
    if (!formState.questions.sections) {
      return;
    }

    return formState.questions.sections.map((section, index) => {
      return (
        <div className="sections" key={index}>
          <p>{section.sectionTitle}</p>
          {renderSectionQuestions(section.questions)}
        </div>
      );
    });
  };

  return (
    <div className="services-container">
      {renderTitle()}
      <div className="questionsContainer">
        <form>{renderQuestions()}</form>
      </div>
    </div>
  );
}

export default WithValidToken(Contract);
