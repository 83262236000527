import React, { useState, useEffect } from 'react';
import isEqual from 'lodash.isequal';

const Option = (props) => {
  const { handleOptionChange, initialData, handleDeleteOption } = props;
  const [option, setOption] = useState({
    optionId: initialData.optionId,
    optionTitle: initialData.optionTitle,
  });

  useEffect(() => {
    if (!isEqual(option, initialData)) {
      handleOptionChange(option);
    }
  }, [option]);

  const handleChange = (event) => {
    setOption({
      ...option,
      optionTitle: event.target.value,
    });
  };

  return (
    <>
      <button
        className="circle-button delete-question"
        onClick={handleDeleteOption}
        data-option-id={initialData.optionId}
        type="button"
      >
        x
      </button>
      <input
        type="text"
        placeholder="Opcion"
        name={`opcion-`}
        data-option-id={initialData.optionId}
        onBlur={handleChange}
        defaultValue={option.optionTitle}
      />
    </>
  );
};

export default Option;
