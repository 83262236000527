import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HtmlEditor, MenuBar } from '@aeaton/react-prosemirror';
import { options, menu } from '@aeaton/react-prosemirror-config-default';
import MicRecorder from 'mic-recorder-to-mp3';
import WithValidToken from '../../common/hoc/WithValidToken';
import ContractsService from '../../Services/ContractsService';
import UploadService from '../../Services/UploadService';
import Spinner from '../../common/components/Spinner';

const Mp3Recorder = new MicRecorder({ bitRate: 160 });

const saveMessage = localStorage.getItem('message');

function Message(props) {
  const history = useHistory();
  const params = useParams();
  const [messages, setMessages] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState([]);
  const [gCloudUrl, setGCloudUrl] = useState(null);
  const [audioFile, setAudioFIle] = useState([]);
  const [isBlocked, setIsBlocked] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      question: null,
      allowNew: true,
    },
    touched: {},
    errors: {},
  });

  const getInitialMessages = () => {
    ContractsService.getContractMessages(params.id)
      .then((messages) => {
        setMessages(messages);
      })
      .catch(() => setMessages([]));
  };

  useEffect(() => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    if (saveMessage) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          question: saveMessage,
        },
        touched: {
          ...formState.touched,
          question: true,
        },
      }));
    }

    if (history.location?.state?.serviceId) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          serviceId: history.location.state.serviceId,
          serviceName: history.location.state.serviceName,
          contractId: params.id,
          userName: history.location.state.userName,
        },
      }));
    }

    getInitialMessages();
    try {
      navigator.getUserMedia(
        { audio: true },
        () => {
          console.log('Permission Granted');
          setIsBlocked(false);
        },
        () => {
          console.log('Permission Denied');
          setIsBlocked(true);
        }
      );
    } catch (error) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => setIsBlocked(false))
        .catch(() => setIsBlocked(true));
    }
  }, []);

  const handleHtmlEditor = (value, name) => {
    localStorage.setItem('message', value);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value,
      },
      touched: {
        ...formState.touched,
        [name]: true,
      },
    }));
  };

  const renderForm = (event) => {
    let form = null;

    form = (
      <form onSubmit={handleSubmit}>
        <div className="form-field">
          <label htmlFor="description">
            <b>Responder con Audio</b>
          </label>
          <br />
          {!isRecording && (
            <button
              className="recordButton"
              onClick={handleStart}
              disabled={isRecording}
              type="button"
            >
              <i className="zwicon-microphone"></i>
            </button>
          )}
          {isRecording && (
            <button
              className="recordRecordButton"
              onClick={handleStop}
              disabled={!isRecording}
              type="button"
            >
              <i class="zwicon-send"></i>
            </button>
          )}
          <br />
          {blobURL.map((blob) => (
            <div>
              <audio src={blob} controls="controls" type="audio/mpeg" />{' '}
            </div>
          ))}
          <hr />
          <div className="textarea-container">
            <HtmlEditor
              name="question"
              options={options}
              value={
                formState.values.question || localStorage.getItem('message')
              }
              onChange={(v) => handleHtmlEditor(v, 'question')}
              render={({ editor, view }) => (
                <div>
                  <MenuBar menu={menu} view={view} />
                  {editor}
                </div>
              )}
            />
          </div>
        </div>
        <div className="form-field">
          {disableButton && <Spinner />}
          {!disableButton && (
            <button
              className="button"
              onClick={handleChange}
              disabled={disableButton}
              type="submit"
            >
              Click para enviar dudas
            </button>
          )}
        </div>
      </form>
    );

    return form;
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : !event.target.files
            ? event.target.value
            : event.target.files[0],
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleStart = () => {
    if (isBlocked) {
      console.log('Permission Denied');
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const handleStop = () => {
    const audioName = `${formState.values.contractId}-${+new Date()}.mp3`;
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, audioName, {
          type: blob.type,
          lastModified: Date.now(),
        });

        // setAudioFIle([...audioFile, file]);

        // const blobUrl = URL.createObjectURL(blob);
        // setBlobURL([...blobURL, blobUrl]);
        UploadService.getSignedUrl({
          filename: audioName,
          bucketType: 'audios',
        }).then((url) => {
          const data = new FormData();
          data.append('filename', file, file.name);
          data.append('contentType', 'audio/mpeg');
          fetch(url.signed_url, {
            method: 'PUT',
            body: data,
            headers: { 'Content-Type': 'audio/mpeg' },
          })
            .then(async () => {
              await ContractsService.addContractMessage({
                ...formState.values,
                question: audioName,
                from: 'admin',
                type: 'audio',
              }).then(async (response) => {
                await UploadService.makePublic({
                  bucketType: 'audios',
                  filename: audioName,
                });
                getInitialMessages();
              });
            })
            .catch((error) => console.log(error));
        });
        setIsRecording(false);
      })
      .catch((e) => console.log(e));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formState.values.question) {
      return;
    }

    setDisableButton(true);

    await ContractsService.addContractMessage({
      ...formState.values,
      from: 'admin',
      type: 'text',
    }).then((response) => {
      setDisableButton(false);
      setMessages(response);
      localStorage.removeItem('message');

      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          question: null,
        },
        touched: {
          ...formState.touched,
          question: true,
        },
      }));
    });
  };

  const renderMessageType = (message, index) => {
    let msgHtml;

    if (message.type === 'text') {
      msgHtml = (
        <div
          className={
            message.from === 'admin' ? 'message-admin' : 'message-user'
          }
          key={index}
          dangerouslySetInnerHTML={{
            __html: message.message,
          }}
        ></div>
      );
    } else if (message.type === 'audio') {
      msgHtml = (
        <div className="message-admin audio-message" key={index}>
          <div
            style={{
              backgroundColor: 'var(--white)',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <audio controls>
              <source
                src={`https://storage.googleapis.com/mimate-audios/${message.message}`}
                key={index}
                type="audio/mpeg"
                preload="metadata"
              />
            </audio>
            <button
              className="circle-button"
              onClick={handleDeleteAudio}
              data-name={message.message}
              data-type="audios"
              data-id={message.id}
            >
              &times;
            </button>
          </div>
        </div>
      );
    }

    return msgHtml;
  };

  const handleDeleteAudio = (event) => {
    event.persist();
    const fileType = event.target.dataset.type;
    const fileName = event.target.dataset.name;
    const fileId = event.target.dataset.id;
    UploadService.deleteServiceFile({
      bucketType: fileType,
      filename: fileName,
    }).then(async () => {
      // borro el mensaje
      await ContractsService.updateContractMessage({
        contractId: formState.values.contractId,
        messageId: fileId,
      }).then(() => {
        // actualizo los mensaje con lo que me viene del backend
        getInitialMessages();
      });
    });
  };

  const renderMessages = () => {
    if (!messages) {
      return;
    }

    if (!messages.messages || messages.messages.length === 0) {
      return;
    }

    return (
      messages.messages &&
      messages.messages.map((message, index) => {
        if (message.message) {
          return renderMessageType(message, index);
        } else {
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            ></div>
          );
        }
      })
    );
  };

  return (
    <div className="services-container">
      <div className="title">
        <h5>
          Contestar Preguntas a: {formState.values.userName} -{' '}
          {formState.values.serviceName}
        </h5>
      </div>
      <div className="message-container">{renderMessages()}</div>
      <div className="message-container-form">{renderForm()}</div>
    </div>
  );
}

export default WithValidToken(Message);
