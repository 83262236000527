import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import WithValidToken from '../../common/hoc/WithValidToken';
import Spinner from '../../common/components/Spinner';
import ContractsService from '../../Services/ContractsService';

const Messages = (props) => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    ContractsService.getPendingMessages().then((response) => {
      const lastedConversation = response.filter((conversation) => {
        const lastMessage =
          conversation.messages[conversation.messages.length - 1];
        if (lastMessage?.from && lastMessage.from === 'user') {
          return conversation;
        }
      });

      setMessages(lastedConversation);
      setLoading(false);
    });
  }, []);

  const renderMessages = () => {
    if (messages.length === 0) {
      return;
    }

    return messages.map((message) => {
      return (
        <tr key={message._id}>
          <td>
            <Link
              to={{
                pathname: `/messages/${message.contract._id}`,
                state: {
                  serviceId: message.service._id,
                  contractId: message.contract._id,
                  serviceName: message.service.name,
                  userName: message.contract.name,
                },
              }}
              className="link"
            >
              {message.contract.name}
            </Link>
          </td>
          <td>
            {' '}
            <Link
              to={{
                pathname: `/messages/${message.contract._id}`,
                state: {
                  serviceId: message.service._id,
                  contractId: message.contract._id,
                  serviceName: message.service.name,
                  userName: message.contract.name,
                },
              }}
              className="link"
            >
              {message.contract.email}
            </Link>
          </td>
          <td>
            {' '}
            <Link
              to={{
                pathname: `/messages/${message.contract._id}`,
                state: {
                  serviceId: message.service._id,
                  contractId: message.contract._id,
                  serviceName: message.service.name,
                  userName: message.contract.name,
                },
              }}
              className="link"
            >
              {message.service.name}
            </Link>
          </td>
          <td>
            {new Intl.DateTimeFormat('es-ES', {
              dateStyle: 'short',
              timeStyle: 'short',
            }).format(new Date(message.updated_at))}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="users-container">
      <div className="title">
        <h1>Mensajes Pendientes</h1>
      </div>
      {loading && <Spinner />}
      {!loading && (
        <div className="table-container">
          <table className="payment-table">
            <thead>
              <tr>
                <th>Usuario</th>
                <th>Correo</th>
                <th>Servicio</th>
                <th>Fecha</th>
              </tr>
            </thead>
            <tbody>{renderMessages()}</tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default WithValidToken(Messages);
