import fetchCall from './ComunicationService';

const UsersService = () => {
  const getUsers = (term = null, value = null, page = 1) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    const url = term
      ? `users/all?term=${term}&value=${value}`
      : page !== 1
      ? `users/all?page=${page}`
      : 'users/all';
    return fetchCall
      .http(url, {}, headers, 'GET', {})
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  const getUserById = (id) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`users/user/${id}`, {}, headers, 'GET', {})
      .then((response) => response.user)
      .catch((error) => console.log(error));
  };

  const editUserById = (data) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`users/user/${data.id}`, {}, headers, 'PUT', data)
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  const requestNewPassword = (id) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`users/password/${id}`, {}, headers, 'POST', {}, false)
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  return {
    getUsers,
    getUserById,
    editUserById,
    requestNewPassword,
  };
};

export default UsersService();
