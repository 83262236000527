import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import WithValidToken from '../../common/hoc/WithValidToken';
import ContractsService from '../../Services/ContractsService';
import DashboardModal from './DashboardModal';
import './dashboard.css';

const Dashboard = (props) => {
  const [latestPayment, setLatestPayment] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [contractId, setContractId] = useState(null);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [userContractName, setUserContractName] = useState(null);
  const history = useHistory();

  const getContracts = () => {
    ContractsService.getLatestPayments().then((response) => {
      setLatestPayment(response);
    });
  };

  useEffect(() => {
    getContracts();
  }, []);

  const handleClick = (event) => {
    const contractId = event.target.dataset.id;
    if (
      window.confirm(
        `¿Deseas marcar el servicio de: ${event.target.dataset.name} como completado?`
      )
    ) {
      ContractsService.markAsCompleted(contractId).then(() => {
        getContracts();
      });
    }
  };

  const showQuestionary = (event) => {
    const contractId = event.target.dataset.id;
    const clientName = event.target.dataset.name;
    history.push({
      pathname: `contract/${contractId}`,
      state: { clientName },
    });
  };

  const handleNewMessage = (event) => {
    const contractId = event.target.dataset.id;
    const serviceId = event.target.dataset.sid;
    const serviceName = event.target.dataset.service;
    const clientName = event.target.dataset.name;
    history.push({
      pathname: `/messages/${contractId}`,
      state: {
        serviceId: serviceId,
        contractId: contractId,
        serviceName: serviceName,
        userName: clientName,
      },
    });
  };

  const handleEditDates = (event) => {
    event.persist();
    setContractId(event.target.dataset.id);
    setUserContractName(event.target.dataset.name);

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        start_date: event.target.dataset.start,
        end_date: event.target.dataset.end,
        next_call_date: event.target.dataset.call,
        next_call_time: event.target.dataset.time,
      },
    }));

    setShowModal(true);
  };

  const handleSubmitTime = (event) => {
    event.preventDefault();
    ContractsService.saveContractDates({
      ...formState.values,
      contractId: contractId,
    }).then(() => {
      setShowModal(false);
      getContracts();
    });
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const renderLatestContracts = () => {
    if (latestPayment.length === 0) {
      return;
    }
    return latestPayment.map((payment) => (
      <tr key={payment._id}>
        <td>
          <button
            className="circle-button"
            data-id={payment._id}
            data-name={payment.name}
            data-start={payment.start_date}
            data-end={payment.end_date}
            data-call={payment.next_call_date}
            data-time={payment.next_call_time}
            onClick={handleEditDates}
          >
            <span
              role="img"
              aria-label="editar"
              style={{ 'pointer-events': 'none' }}
            >
              <i class="zwicon-pencil"></i>
            </span>
          </button>
        </td>
        <td>
          <small>{payment.name}</small>
        </td>
        <td>
          <small>{payment.email}</small>
        </td>
        <td>
          <small>{payment.service_id.name}</small>
        </td>
        <td>
          {payment.start_date &&
            new Intl.DateTimeFormat('es-ES', {
              dateStyle: 'short',
            }).format(new Date(payment.start_date))}
        </td>
        <td>
          {payment.end_date &&
            new Intl.DateTimeFormat('es-ES', {
              dateStyle: 'short',
            }).format(new Date(payment.end_date))}
        </td>
        <td>
          {payment.next_call_date &&
            new Intl.DateTimeFormat('es-ES', {
              dateStyle: 'short',
            }).format(new Date(payment.next_call_date))}{' '}
          - {payment.next_call_time}
        </td>
        <td>
          {payment.questionary_completed && (
            <button
              className="circle-button"
              data-id={payment._id}
              data-name={payment.name}
              onClick={showQuestionary}
            >
              <i class="zwicon-comment"></i>
            </button>
          )}
        </td>
        <td>
          <button
            className="circle-button"
            data-id={payment._id}
            data-sid={payment.service_id._id}
            data-service={payment.service_id.name}
            data-name={payment.name}
            onClick={handleNewMessage}
          >
            <i class="zwicon-mail"></i>
          </button>
        </td>
        <td>
          {new Intl.DateTimeFormat('es-ES', {
            dateStyle: 'short',
            timeStyle: 'short',
          }).format(new Date(payment.created_at))}
        </td>
        <td>
          <button
            className="circle-button"
            data-id={payment._id}
            data-name={payment.name}
            onClick={handleClick}
          >
            <i class="zwicon-checkmark-circle"></i>
          </button>
        </td>
      </tr>
    ));
  };
  return (
    <div className="dashboard-container">
      <div style={{ maxWidth: '1185px' }}>
        <h5>Últimos Servicios Contrados</h5>
        <div className="dashboard-table-container">
          <table className="payment-table">
            <thead>
              <tr>
                <th></th>
                <th>Cliente</th>
                <th>Email</th>
                <th>Servicio</th>
                <th>Inicio</th>
                <th>Fin</th>
                <th>Proxima Llamada</th>
                <th>Q</th>
                <th>Msg</th>
                <th>Comprado</th>
                <th>Finalizado</th>
              </tr>
            </thead>
            <tbody>{renderLatestContracts()}</tbody>
          </table>
        </div>
        <br />
        <h5>Plazas disponibles por servicio</h5>
        <table className="payment-table">
          <thead>
            <tr>
              <th>Servicio</th>
              <th># Plazas</th>
              <th></th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
      <DashboardModal
        showModal={showModal}
        setShowModal={setShowModal}
        userContractName={userContractName}
        handleChange={handleChange}
        formState={formState}
        handleSubmitTime={handleSubmitTime}
      />
    </div>
  );
};

export default WithValidToken(Dashboard);
