export default {
  0: {
    sTotal: '16 - 18 Horas',
    sDiurno: '7 - 9 Horas',
    sNocturno: '8 - 9 Horas',
    ventanas: '45 - 60 Minutos',
    siestas: 'Pequeñas siestas',
  },
  1: {
    sTotal: '16 - 18 Horas',
    sDiurno: '7 - 9 Horas',
    sNocturno: '8 - 9 Horas',
    ventanas: '45 - 60 Minutos',
    siestas: 'Pequeñas siestas',
  },
  2: {
    sTotal: '14 - 16 Horas',
    sDiurno: '3 - 5 Horas',
    sNocturno: '9 - 10 Horas',
    ventanas: '1 - 1:45 Horas',
    siestas: '4 - 3 siestas',
  },
  3: {
    sTotal: '14 - 16 Horas',
    sDiurno: '3 - 5 Horas',
    sNocturno: '9 - 10 Horas',
    ventanas: '1 - 1:45 Horas',
    siestas: '4 - 3 siestas',
  },
  4: {
    sTotal: '16 - 15 Horas',
    sDiurno: '3 - 4 Horas',
    sNocturno: '10 - 12 Horas',
    ventanas: '1:30 - 2:30 Horas',
    siestas: '3 siestas',
  },
  5: {
    sTotal: '16 - 15 Horas',
    sDiurno: '3 - 4 Horas',
    sNocturno: '10 - 12 Horas',
    ventanas: '1:30 - 2:30 Horas',
    siestas: '3 siestas',
  },
  6: {
    sTotal: '14 Horas',
    sDiurno: '3 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '2:30 - 3 Horas',
    siestas: '3 - 2 siestas',
  },
  7: {
    sTotal: '14 Horas',
    sDiurno: '3 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '2:30 - 3 Horas',
    siestas: '3 - 2 siestas',
  },
  8: {
    sTotal: '14 Horas',
    sDiurno: '3 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '2:30 - 3 Horas',
    siestas: '3 - 2 siestas',
  },
  9: {
    sTotal: '14 Horas',
    sDiurno: '2 - 3 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '3:30 - 4 Horas',
    siestas: '2 siestas',
  },
  10: {
    sTotal: '14 Horas',
    sDiurno: '2 - 3 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '3:30 - 4 Horas',
    siestas: '2 siestas',
  },
  11: {
    sTotal: '14 Horas',
    sDiurno: '2 - 3 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '3:30 - 4 Horas',
    siestas: '2 siestas',
  },
  12: {
    sTotal: '13 - 14 Horas',
    sDiurno: '2 - 3 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '4 - 5 Horas',
    siestas: '2 - 1 siesta',
  },
  13: {
    sTotal: '13 - 14 Horas',
    sDiurno: '2 - 3 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '4 - 5 Horas',
    siestas: '2 - 1 siesta',
  },
  14: {
    sTotal: '13 - 14 Horas',
    sDiurno: '2 - 3 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '4 - 5 Horas',
    siestas: '2 - 1 siesta',
  },
  15: {
    sTotal: '13 - 14 Horas',
    sDiurno: '2 - 3 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '4 - 5 Horas',
    siestas: '2 - 1 siesta',
  },
  16: {
    sTotal: '13 - 14 Horas',
    sDiurno: '2 - 3 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '4 - 5 Horas',
    siestas: '2 - 1 siesta',
  },
  17: {
    sTotal: '13 - 14 Horas',
    sDiurno: '2 - 3 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '4 - 5 Horas',
    siestas: '2 - 1 siesta',
  },
  18: {
    sTotal: '13 - 14 Horas',
    sDiurno: '2 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '5 - 6 Horas',
    siestas: '1 siesta',
  },
  19: {
    sTotal: '13 - 14 Horas',
    sDiurno: '2 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '5 - 6 Horas',
    siestas: '1 siesta',
  },
  20: {
    sTotal: '13 - 14 Horas',
    sDiurno: '2 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '5 - 6 Horas',
    siestas: '1 siesta',
  },
  21: {
    sTotal: '13 - 14 Horas',
    sDiurno: '2 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '5 - 6 Horas',
    siestas: '1 siesta',
  },
  22: {
    sTotal: '13 - 14 Horas',
    sDiurno: '2 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '5 - 6 Horas',
    siestas: '1 siesta',
  },
  23: {
    sTotal: '13 - 14 Horas',
    sDiurno: '2 Horas',
    sNocturno: '11 - 12 Horas',
    ventanas: '5 - 6 Horas',
    siestas: '1 siesta',
  },
  24: {
    sTotal: '12 - 14 Horas',
    sDiurno: '1 - 2 Horas',
    sNocturno: '11  -12 Horas',
    ventanas: '5 - 6 Horas',
    siestas: '1 siesta',
  },
};
