import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import validate from 'validate.js';
import Professional from './Professional';
import Streaming from './Streaming';
import Course from './Course';
import ServicesService from '../../Services/ServicesService';
import WithValidToken from '../../common/hoc/WithValidToken';
import EDIT_SCHEMA from './EditSchema';
import Spinner from '../../common/components/Spinner';
import Notification from '../../common/components/Notification';
import GCUploader from '../../common/components/GCUploader';
import QuestionaryGenerator from '../../common/components/QuestionaryGenerator';
import UploadService from '../../Services/UploadService';
import './new.css';

const Edit = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      service_type: 'professional',
      active: false,
      show_availability: false,
      allow_messages: false,
      show_reservio: false,
      iva21: false,
      intro_document: [],
    },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    ServicesService.getService(id).then((service) => {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          ...service,
        },
      }));
      switch (service.service_type) {
        case 'streaming': {
          setDocuments(
            service.streaming_course &&
              service.streaming_course.streaming_documents_url
              ? service.streaming_course.streaming_documents_url
              : []
          );
          if (
            service.streaming_course &&
            service.streaming_course.streaming_show_documents
          ) {
            setFormState((formState) => ({
              ...formState,
              values: {
                ...formState.values,
                ...service,
                show_documents: true,
              },
            }));
          }
          break;
        }
        case 'professional': {
          setDocuments(
            service.professional_services &&
              service.professional_services.professional_services_documents_url
              ? service.professional_services
                  .professional_services_documents_url
              : []
          );
          if (
            service.professional_services &&
            service.professional_services.professional_services_show_documents
          ) {
            setFormState((formState) => ({
              ...formState,
              values: {
                ...formState.values,
                ...service,
                show_documents: true,
              },
            }));
          }
          break;
        }
        case 'document': {
          setDocuments(
            service.documents_services &&
              service.documents_services.document_documents_url
              ? service.documents_services.document_documents_url
              : []
          );
          if (
            service.documents_services &&
            service.documents_services.document_show_documents
          ) {
            setFormState((formState) => ({
              ...formState,
              values: {
                ...formState.values,
                ...service,
                show_documents: true,
              },
            }));
          }
          break;
        }

        default:
          break;
      }
    });
    setLoading(false);
  }, [id]);

  useEffect(() => {
    const errors = validate(formState.values, EDIT_SCHEMA);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleCancel = (event) => {
    history.push('/services');
  };

  const handleHtmlEditor = (value, name) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [name.split('.')[0]]: {
          ...formState.values[name.split('.')[0]],
          [name.split('.')[1]]: value,
        },
      },
      touched: {
        ...formState.touched,
        [name.split('.')[0]]: {
          [name.split('.')[1]]: true,
        },
      },
    }));
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : !event.target.files
            ? event.target.value
            : event.target.files[0],
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleChangeOther = (event) => {
    event.persist();

    const originalValues = formState.values[event.target.name.split('.')[0]];
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name.split('.')[0]]: {
          ...originalValues,
          [event.target.name.split('.')[1]]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : !event.target.files
              ? event.target.value
              : event.target.files[0],
        },
      },
      touched: {
        ...formState.touched,
        [event.target.name.split('.')[0]]: {
          [event.target.name.split('.')[1]]: true,
        },
      },
    }));
  };

  const hasError = (field) => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formState.isValid) {
      return;
    }

    const data = new FormData();
    data.append('name', formState.values.name);
    data.append('category', formState.values.category);
    data.append('service_type', formState.values.service_type);
    data.append('summary', formState.values.summary);
    data.append('active', formState.values.active);
    if (formState.touched.image_cover) {
      data.append('image', formState.values.image_cover);
    }
    data.append('price', formState.values.price);
    data.append('expire_in', formState.values.expire_in);

    data.append('facebook_content_id', formState.values.facebook_content_id);
    if (
      formState.values.show_availability &&
      formState.values.availability_date
    ) {
      data.append('show_availability', formState.values.show_availability);
      data.append('availability_date', formState.values.availability_date);
    } else if (
      formState.values.show_availability &&
      !formState.values.availability_date
    ) {
      alert('Debe indicar una fecha de disponibilidad');
      return;
    }

    if (formState.values.professional_services) {
      const professionalService = formState.values.professional_services;
      if (documents.length >= 0) {
        professionalService.professional_services_documents_url = documents;
      }
      if (formState.values.show_documents) {
        professionalService.professional_services_show_documents = true;
      } else {
        professionalService.professional_services_show_documents = false;
      }
      data.append('professional_services', JSON.stringify(professionalService));
    }

    if (formState.values.streaming_course) {
      const streamingCourse = formState.values.streaming_course;
      if (documents.length >= 0) {
        streamingCourse.streaming_documents_url = documents;
      }
      if (formState.values.show_documents) {
        streamingCourse.streaming_show_documents = true;
      } else {
        streamingCourse.streaming_show_documents = false;
      }
      data.append('streaming_course', JSON.stringify(streamingCourse));
    }

    if (formState.values.video_course) {
      const videoCourse = formState.values.video_course;

      if (formState.values.show_documents) {
        videoCourse.show_documents = true;
      } else {
        videoCourse.show_documents = false;
      }
      data.append('video_course', JSON.stringify(videoCourse));
    }

    if (formState.values.service_type === 'document') {
      const documentService = formState.values.documents_services || {};
      if (documents.length >= 0) {
        documentService.document_documents_url = documents;
      }
      if (formState.values.show_documents) {
        documentService.document_show_documents = true;
      } else {
        documentService.document_show_documents = false;
      }
      data.append('documents_services', JSON.stringify(documentService));
    }

    if (formState.values.initial_questions) {
      data.append(
        'initial_questions',
        JSON.stringify(formState.values.initial_questions)
      );
    }

    data.append(
      'allow_messages',
      JSON.stringify(formState.values.allow_messages)
    );

    data.append(
      'show_reservio',
      JSON.stringify(formState.values.show_reservio)
    );

    data.append('iva21', JSON.stringify(formState.values.iva21));

    if (formState.values.intro_video) {
      data.append('intro_video', formState.values.intro_video);
    }

    if (
      formState.values.intro_document &&
      formState.values.intro_document.length > 0
    ) {
      data.append('intro_document', formState.values.intro_document);
    }

    await ServicesService.editServices(id, data).then((response) => {
      setShowSuccess(response.updated);
    });
  };

  const handleQuestionary = (questionary) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        initial_questions: questionary,
      },
    }));
  };

  const handleUploadFile = (event) => {
    event.persist();

    const filename =
      event.target.dataset.filetype === 'videos'
        ? `${formState.values.slug}.${event.target.files[0].name
            .split('.')
            .pop()}`
        : `${formState.values.slug}-${+new Date()}.${event.target.files[0].name
            .split('.')
            .pop()}`;

    // obtengo signed url
    if (event.target.dataset.filetype === 'videos') {
      setLoadingVideo(true);
      setLoadingFile(false);
    } else {
      setLoadingFile(true);
      setLoadingVideo(false);
    }
    UploadService.getSignedUrl({
      filename: filename,
      bucketType: event.target.dataset.filetype,
    }).then((url) => {
      // hago el upload del archivo
      const data = new FormData();
      data.append('filename', event.target.files[0], filename);

      fetch(url.signed_url, {
        method: 'PUT',
        body: data,
        headers: { 'Content-Type': 'application/octet-stream' },
      })
        .then(async () => {
          const data = new FormData();
          if (event.target.dataset.filetype === 'videos') {
            setFormState((formState) => ({
              ...formState,
              values: {
                ...formState.values,
                intro_video: filename,
              },
            }));
            data.append('intro_video', filename);
          } else {
            let allDocuments;
            if (
              formState.values.intro_document &&
              formState.values.intro_document.length > 0
            ) {
              allDocuments = [...formState.values.intro_document, filename];
            } else {
              allDocuments = [filename];
            }

            setFormState((formState) => ({
              ...formState,
              values: {
                ...formState.values,
                intro_document: allDocuments,
              },
            }));
            data.append('intro_document', allDocuments);
          }

          await UploadService.makePublic({
            bucketType: event.target.dataset.filetype,
            filename,
          });

          await ServicesService.editServices(id, data).then((response) => {
            setShowSuccess(response.updated);
            setLoadingVideo(false);
            setLoadingFile(false);
          });
        })
        .catch((error) => console.log(error));
    });
  };

  const renderVideo = () => {
    if (formState.values.intro_video) {
      return (
        <table>
          <tr>
            <td>
              <button
                className="button-error"
                onClick={handleDeleteFile}
                data-type="videos"
                data-name={formState.values.intro_video}
                type="button"
              >
                Eliminar
              </button>
            </td>
            <td>
              <a
                className="button-success"
                href={`https://storage.googleapis.com/mimate-videos/${formState.values.intro_video}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="zwicon-video"></i> ver video
              </a>
            </td>
          </tr>
        </table>
      );
    }
  };

  const handleDeleteFile = (event) => {
    event.persist();
    const fileType = event.target.dataset.type;
    const fileName = event.target.dataset.name;
    UploadService.deleteServiceFile({
      bucketType: fileType,
      filename: fileName,
    }).then(async () => {
      if (fileType === 'files') {
        const filterFiles = formState.values.intro_document.filter(
          (doc) => doc !== fileName
        );

        const data = new FormData();
        data.append(
          'intro_document',
          filterFiles.length > 0 ? filterFiles : []
        );
        await ServicesService.editServices(id, data).then(() => {
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              intro_document: filterFiles,
            },
          }));
        });
      } else if (fileType === 'videos') {
        const data = new FormData();
        data.append('intro_video', null);
        await ServicesService.editServices(id, data).then(() => {
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              intro_video: null,
            },
          }));
        });
      }
    });
  };

  const renderFiles = () => {
    if (
      formState.values.intro_document &&
      formState.values.intro_document.length > 0
    ) {
      return formState.values.intro_document.map((introDoc, index) => {
        return (
          <table key={index}>
            <tr>
              <td>
                <button
                  className="button-error"
                  onClick={handleDeleteFile}
                  data-type="files"
                  data-name={introDoc}
                  type="button"
                >
                  Eliminar
                </button>
              </td>
              <td>
                <a
                  className="button-success"
                  href={`https://storage.googleapis.com/mimate-files/${introDoc}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i class="zwicon-document"></i> ver archivo
                </a>
              </td>
            </tr>
          </table>
        );
      });
    }
  };

  return (
    <div className="services-container">
      <div className="title">
        <h1>Editar Servicios</h1>
      </div>
      {loading && <Spinner />}
      <form className="new-form" onSubmit={handleSubmit}>
        <div className="form-field">
          <label htmlFor="name">
            <b>Nombre del Servicio</b>
          </label>
          <input
            type="text"
            placeholder="Nombre del Servicio"
            name="name"
            onChange={handleChange}
            defaultValue={formState.values.name}
          />
          {hasError('name') && (
            <span className="field-error">Nombre es requerido</span>
          )}
        </div>
        <div className="form-field">
          <label htmlFor="category">
            <b>Categoria del Servicio</b>
          </label>
          <input
            type="text"
            placeholder="Categoria del Servicio"
            name="category"
            onChange={handleChange}
            defaultValue={formState.values.category}
          />
          {hasError('category') && (
            <span className="field-error">Categoria es requerido</span>
          )}
        </div>
        <div className="form-field">
          <label htmlFor="service_type">
            <b>Tipo Servicio</b>
          </label>
          <select
            name="service_type"
            onChange={handleChange}
            value={formState.values.service_type}
          >
            <option value="professional">Servicio Profesional</option>
            <option value="streaming">Webminar</option>
            <option value="document">Documentos</option>
            <option value="video">Video Curso</option>
          </select>
        </div>
        <div className="form-field">
          <label htmlFor="active">
            <b>Activo</b>
          </label>
          <br />
          <input
            type="checkbox"
            name="active"
            checked={formState.values.active}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label htmlFor="description">
            <b>Resumen</b>
          </label>
          <textarea
            name="summary"
            onChange={handleChange}
            value={formState.values.summary || ''}
          />
          {hasError('description') && (
            <span className="field-error">resumen es requerido</span>
          )}
        </div>
        <div className="form-field">
          <label htmlFor="image_cover">
            <b>Imagen Portada</b>
          </label>
          <br />
          <input
            name="image_cover"
            type="file"
            defaultValue={formState.values.description}
            onChange={handleChange}
            required
          />
          {hasError('image_cover') && (
            <span className="field-error">
              La imagen de portada es requerido
            </span>
          )}
        </div>
        <div className="form-field">
          <label htmlFor="price">
            <b>
              Precio <sup>Cero representa que el servicio es gratis</sup>
            </b>
          </label>
          <input
            type="number"
            placeholder="Precio del Servicio"
            name="price"
            onChange={handleChange}
            required
            defaultValue={formState.values.price}
          />
          {hasError('price') && (
            <span className="field-error">precio debe ser un número</span>
          )}
        </div>
        <div className="form-field">
          <label htmlFor="expire">
            <b>
              Expira en <sup>Cero representa que nunca expira</sup>
            </b>
          </label>
          <input
            type="number"
            placeholder="Días a los que expira el servicio"
            name="expire_in"
            onChange={handleChange}
            required
            defaultValue={formState.values.expire_in}
          />
          {hasError('expire_in') && (
            <span className="field-error">Expira debe ser un número</span>
          )}
        </div>
        {/** section professional services */}
        {formState.values.service_type === 'professional' && (
          <Professional
            formState={formState}
            handleHtmlEditor={handleHtmlEditor}
            hasError={hasError}
            handleChangeOther={handleChangeOther}
          />
        )}
        {/** section straming */}
        {formState.values.service_type === 'streaming' && (
          <Streaming
            formState={formState}
            handleHtmlEditor={handleHtmlEditor}
            hasError={hasError}
            handleChangeOther={handleChangeOther}
          />
        )}
        {formState.values.service_type === 'video' && (
          <Course
            formState={formState}
            handleHtmlEditor={handleHtmlEditor}
            hasError={hasError}
            handleChangeOther={handleChangeOther}
          />
        )}
        <div className="form-field">
          <label>
            <b>
              Preguntas Iniciales &nbsp;
              <sub>
                Preguntas que debe contestar el usuario antes de comenzar el
                servicio
              </sub>
            </b>
          </label>
          {
            <QuestionaryGenerator
              handleQuestionary={handleQuestionary}
              initialData={
                formState.values.initial_questions
                  ? formState.values.initial_questions
                  : null
              }
            />
          }
        </div>
        <div className="form-field">
          <label>
            <b>
              Documentos <sub>Adjuntar Documentos del Servicio</sub>
            </b>
          </label>
          <GCUploader
            totalDocuments={documents}
            serviceName={formState.values.name}
            setDocuments={setDocuments}
          />
        </div>
        <div className="form-field">
          <label>
            <input
              type="checkbox"
              id="show_documents"
              name="show_documents"
              onChange={handleChange}
              defaultChecked={formState.values.show_documents}
            />
            Mostrar Documentos al Usuario
          </label>
          <br />
        </div>
        <hr />
        <div className="form-field">
          <label htmlFor="facebook_content_id">
            <b>Facebook Content Id</b>
          </label>
          <input
            type="text"
            placeholder="Facebook Content Id"
            name="facebook_content_id"
            onChange={handleChange}
            defaultValue={formState.values.facebook_content_id}
          />
        </div>
        <hr />
        <div className="form-field">
          <label htmlFor="active">
            <b>Mostar Popup de Disponibilidad</b>
          </label>
          <br />
          <input
            type="checkbox"
            name="show_availability"
            checked={formState.values.show_availability}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label htmlFor="streaming_course.streaming_date">
            <b>
              Fecha Disponibilidad
              <sup>
                Esta fecha se mostrara en el popup indicando desde cuando estara
                disponible el servicio
              </sup>
            </b>
          </label>
          <br />
          <input
            type="date"
            placeholder="Fecha"
            name="availability_date"
            onChange={handleChange}
            defaultValue={formState.values.availability_date}
          />
        </div>
        <hr />
        <div className="form-field">
          <label htmlFor="active">
            <b>Permitir a los usuarios enviar mensajes en este servicio</b>
          </label>
          <br />
          <input
            type="checkbox"
            name="allow_messages"
            checked={formState.values.allow_messages}
            onChange={handleChange}
          />
          Si
        </div>
        <hr />
        <div className="form-field">
          <label htmlFor="active">
            <b>
              Mostrar a los usuarios el link de reservio para agendar su llamada
            </b>
          </label>
          <br />
          <input
            type="checkbox"
            name="show_reservio"
            checked={formState.values.show_reservio}
            onChange={handleChange}
          />
          Si
        </div>
        <hr />
        <div className="form-field">
          <label htmlFor="iva21">
            <b>Este servicio tiene IVA del 21%</b>
          </label>
          <br />
          <input
            type="checkbox"
            name="iva21"
            checked={formState.values.iva21}
            onChange={handleChange}
          />
          Si
        </div>
        <hr />
        <div className="form-field">
          <label htmlFor="intro_video">
            <b>Video Introductorio</b>
          </label>
          <br />
          <div class="upload-btn-wrapper">
            {loadingVideo && <Spinner />}
            {!loadingVideo && <button class="button">Subir Video</button>}
            <input
              type="file"
              data-filetype="videos"
              name="intro_video"
              onChange={handleUploadFile}
              defaultValue={formState.values.intro_video}
            />
          </div>
          <br />
          {renderVideo()}
          <hr />
          <div className="form-field">
            <label htmlFor="intro_document">
              <b>Archivos del Servicio</b>
            </label>
            <br />
            <div class="upload-btn-wrapper">
              {loadingFile && <Spinner />}
              {!loadingFile && <button class="button">Subir Archivo</button>}
              <input
                type="file"
                data-filetype="files"
                name="intro_document"
                onChange={handleUploadFile}
                defaultValue={formState.values.intro_document}
              />
            </div>
          </div>
        </div>
        <br />
        {renderFiles()}
        <br />
        <hr />
        <div className="buttons-container">
          {loading && <Spinner />}
          {!loading && (
            <>
              <div className="cancel-button">
                <button className="button-error" onClick={handleCancel}>
                  Cancelar
                </button>
              </div>
              <div className="save-button">
                <button className="button-success" onClick={handleSubmit}>
                  Guardar
                </button>
              </div>
            </>
          )}
        </div>
        {showSuccess && (
          <Notification severity="success" text="Servicio Actualizado 🎉" />
        )}
      </form>
    </div>
  );
};

export default WithValidToken(Edit);
